<template>
    <textarea class="textarea"></textarea>
</template>

<script>
export default {
    name: 'AppTextarea'
}
</script>

<style lang="scss">
    .textarea {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 6px;

        padding: 11px;

        resize: none;

        width: 100%;
        min-height: 60px;
    }
</style>