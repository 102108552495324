// eslint-disable-next-line to ignore the next line.
Use /* eslint-disable */
<template>
    <section class="section-feedback feedback">
        <div class="container">
            <form class="feedback__form" action="" onsubmit="return false;">
                <div class="product__top">
                    <AppSectionTitle class="product__title">
                        <h1>
                            Оформление заявки
                        </h1>
                    </AppSectionTitle>
                    <div v-if="product">
                        <ul class="cart__table_products cart__product">
                            <li class="cart__table_product">
                                <div class="cart__product_image"
                                    v-lazy-container="{ selector: 'img' }">
                                    <AppImageLink v-if="product.images.length>0"
                                        class="product__image"
                                        :stocks="product.stocks"
                                        :image="product.images[0].webp"/>
                                </div>
                                
                                    {{ product.name }}

                            </li>
                        </ul>
                    </div>
                    
                    <!--
                    <svg class="close-button"
                        @click="$router.push({name: 'PageMain'})"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.627441" y="14.0784" width="19.0226" height="2.71751" transform="rotate(-45 0.627441 14.0784)" fill="#454545"/>
                        <rect width="19.0226" height="2.71751" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 16 14.0784)" fill="#454545"/>
                    </svg>
                    -->
                </div>
                <div class="feedback__inputs">
                    <AppInputForm
                     v-model="client.FCs">
                        <template v-slot:title>
                            ФИО*
                        </template> 
                    </AppInputForm> 
                    <AppInputForm 
                    v-model="client.phone"
                    :typeInput="'tel'"
                            >
                        <template v-slot:title>
                            Телефон*
                        </template>
                    </AppInputForm>
                    <AppInputForm
                    v-model="client.email"
                    :typeInput="'email'">
                        <template v-slot:title>
                            E-mail*
                        </template>
                    </AppInputForm>
                    <div class="feedback__text-message">
                        <div class="feedback__text-message_title">
                            Оставить комментарий 
                        </div>
                        <AppTextareaForm :placeholder="'Информация к заявке'" class="feedback__text-message_textarea"  v-model="comment"></AppTextareaForm>
                    </div>
                </div>
                <AppCheckbox class="feedback__consent" :forAndId="'consent'"  v-model="personal">
                    Я даю согласие на <AppHref>обработку моих персональных данных</AppHref>
                </AppCheckbox>
                <AppButton class="feedback__button"
                @click="handleClick()"
                :styleButton="showSave">
                    Оформить
                </AppButton>
            </form>
            
        </div>
    </section>
</template>

<script>
import api from "../../api"

export default {
    name: "PageState",
    props: ['shop_id'],
    data() {
        return {
            client: {},
            comment: "",
            personal: false,
            product_id: null,
            product: null,
        }
    },
    
    computed: {
        go(){
            
            if (!this.client.FCs) { return false;}
            /*
            if (!this.comment) {
                if (this.comment.length<10) { return false;}
            }
            */ 
            //console.log("name")
            if (this.client.phone<7) {return false;} 
          //   console.log("price")
            if (!this.client.email) { return false;}
            // eslint-disable-next-line no-useless-escape
            var reEmail = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
            if (!reEmail.test(this.client.email)) { return false;}
           
            if (!this.personal) { return false;}
            //  if (!this.offer) { return false;}
    
            return true;
        },
        showSave(){
            
            let class_st = '_red _no-active';
         /*
            if (this.commentt && this.comment.length<10) { 
                return class_st;
                }
            */
           
            if (!this.personal) { return class_st;}
           
           //   if (!this.offer) { return class_st;}
            // eslint-disable-next-line no-useless-escape
            var reEmail = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
            if (!reEmail.test(this.client.email)) { return class_st;}
            if (!this.client.FCs) { return class_st;}
            //console.log("name")
            if (this.client.phone<7) {return class_st;}
          //   console.log("price")
            if (!this.client.email) { return class_st;}
            
            return '_red active';
           
        }
     },
    
    mounted(){
        let initData = window.Telegram.WebApp.initData
        if (!initData) initData = 'query_id=AAH3MwRmAAAAAPczBGag4wv7&user=%7B%22id%22%3A1711551479%2C%22first_name%22%3A%22G%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22G922816%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1658815162&hash=1f3213380c7e4d4024ca44258bc3812f014e9eab182dae613eb20b17e4186acf'
        this.product_id = this.$route.params.product_id
        this.getClient(initData)
        this.getProduct(this.product_id)
    },
    methods: {
        handleClick() {
             if (!this.go) return;
            this.client.shop_id = this.shop_id
            localStorage.setItem('client', JSON.stringify(this.client))
            api.sendState({client: this.client, product_id:this.product_id, "comment":this.comment}).then(
                this.$router.push({ name: 'PageMain', params: { shop_id: this.shop_id}})
            )
        },
         async getProduct(product_id){
            const response =  await api.getProduct(product_id)
            this.product =  response.data.data
        
        },
        async getClient(initData){
            const response =  await api.getClient({initData: initData, shop_id: this.shop_id})
            this.client =  response.data.data
     
        }
    }
}
</script>

<style lang="scss" scoped>
    .feedback__form {
        padding-bottom: 124px;
        margin-top: 25px;
    }
    .product__image {
        margin-bottom: 8px;
        @media (max-width: 400px) {
            grid-gap: 12px;
        }
    }
    .product__top{
        .product__top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        margin-top: 18px;
        margin-bottom: 18px;
    }
    }
    .close-button {
      cursor: pointer;
    }
    .feedback__title {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .feedback__inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 23px 17px;
        @media (max-width: 400px) {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }
    .feedback__consent {
        margin-top: 15px;
        margin-bottom: 17px;
    }
    .feedback__button {
        font-size: 16px;
        padding: 10px 0;
        margin: 0 auto;
        max-width: 172px;
    }
    .feedback__text-message {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
        grid-column: 1/3;
        @media (max-width: 400px) {
            grid-column: 1;
        }
    }
    .feedback__text-message_title {
        font-size: 12px;
        color: var(--global_fourth_color);
    }
    .feedback__text-message_textarea {
        padding: 11px;
        min-height: 82px;
    }
    .cart__content {
        padding: 14px;
        padding-bottom: 35px;
        
        position: relative;
        &::after {
            content: "";
            background: var(--global_block_line);
            position: absolute;
            left: 0;
            top: 50px;
            width: 100%;
            height: 1px;
        }
    }
    .cart__table_names {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 0.9fr 0.75fr;
        text-align: left;
        padding-top: 14px;
        padding-bottom: 25px;
        & > *:first-child {
            padding-right: 8px;
        }
        & > *:nth-child(-n+2) {
            text-align: left;
        }
        @media (max-width: 400px) {
            grid-template-columns: 0.5fr 1.25fr 1fr 0.75fr 0.25fr;
        }
    }
    .cart__table_name {
        color: var(--global_fourth_color);
        font-size: 11px;
        @media( max-width: 400px) {
            &:nth-last-child(2) {
                grid-column: -3/-1;
            }
 
            &:last-child {
                display: none;
            }
        }
    }
    .cart__table_products {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .cart__table_product {
        @extend .cart__table_names;
        align-items: center;
        border-bottom: 1px solid var(--global_block_line);
        padding-top: 0;
        padding-bottom: 10px;
        .counter {
            margin: 0 auto;
        }
    }
    .cart__product_title {
        color: var(--default_background);
    }
    .cart__product_svg {
        margin-left: auto;
    }
    .cart__product_img {
        width: 100%;
    }
    .cart__product_price {
        font-size: 12px;
        &::after {
            content: "";
        }
    }
    .cart__table_final {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 16px;
        & > * {
            color: var(--global_color);
            font-size: 16px;
            font-weight: 500;
        }
    }
    .cart__table_payment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cart__table_clear {
        color: var(--default_background);
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
    }
    .cart__table_payment_button {
        padding: 10px 0;
        width: 172px;
    }
</style>