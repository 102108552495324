<template>
    <div class="filter">
        <AppButton
        class="filter__button"
        @click="showContent = !showContent">
            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="15" height="2" fill="white"/>
                <rect width="15" height="2" fill="white"/>
                <rect width="15" height="2" fill="white"/>
                <rect width="15" height="2" fill="white"/>
                <rect x="4" y="5" width="11" height="2" fill="white"/>
                <rect x="4" y="5" width="11" height="2" fill="white"/>
                <rect x="4" y="5" width="11" height="2" fill="white"/>
                <rect x="4" y="5" width="11" height="2" fill="white"/> 
                <rect x="9" y="10" width="6" height="2" fill="white"/>
                <rect x="9" y="10" width="6" height="2" fill="white"/>
                <rect x="9" y="10" width="6" height="2" fill="white"/>
                <rect x="9" y="10" width="6" height="2" fill="white"/>
            </svg>
        </AppButton>
        <div
        class="filter__content"
        v-if="this.showContent">
            <div class="filter__content_inner">
                <div class="filter__content_top">
                    <div class="filter__content_title">
                        {{ filterContent.title }}
                    </div>
                    <AppX
                    @click="showContent = false"
                    :styleX="'_white'"></AppX>
                </div>
                <div
                class="filter__content_item content-item"
                v-for="filter in filterContent.filters"
                :key="filter.title">
                    <div
                    class="content-item__title"
                    v-if="filter.title">
                        {{ filter.title }} 
                    </div>
                    <div
                    class="content-item__list"
                    v-if="filter.type === 'radio'">
                        <AppRadio    
                        v-for="item in filter.items"
                        :key="item.name"
                        v-model="select"
                        :forAndId="item.name">
                            <div class="content-item__name">
                                {{ item.title }} 
                            </div>
                        </AppRadio>
                    </div>
                    <div
                    class="content-item__list"
                    v-if="filter.type === 'checkbox'">
                        <AppCheckbox
                        v-for="item in filter.items"
                        :key="item"
                        :name="filter.name"
                        :forAndId="item">
                            <div class="content-item__name">
                                {{ item }}
                            </div>
                        </AppCheckbox>
                    </div>
                </div>
                <div class="filter__buttons">
                    <AppButton 
                    @click="goFilter()"
                    :styleButton="'_white'">
                        Сортировать
                    </AppButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFilter",
    data() {
        return {
            showContent: false,
              select:'id,desc',
        };
    },
    props: {
        filterContent: {
            type: Object
        }
    },
    methods:{
        goFilter(){
            this.$emit("goSort", this.select)
            this.showContent = false
        }
       
    }
 
}
</script>

<style lang="scss" scoped>
    .filter {
    }

    .filter__button {
        width: 30px;
        height: 30px;
    }

    .filter__content {
        display: flex;
        justify-content: center;

        position: absolute;
        left: 0;

        width: 100%;

        z-index: 1000;
    }

    .filter__content_inner {
        background: var(--default_background);

        border-radius: 10px;

        color: var(--default_color);

        padding: 9px 15px;
        margin-top: 5px;

        width: 97.5%;
        height: 100%;
    }

    .filter__content_top {
        display: flex;
        justify-content: space-between;
    }

    .filter__content_title {
        font-weight: 500;

        margin-bottom: 10px;
    }

    .content-item {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .content-item__list {
        border-top: var(--default_color);

        display: flex;
        flex-direction: column; 
        row-gap: 15px;

        padding-top: 10px;
    }

    .content-item__title {
        font-size: 13px;
        font-weight: 400;
    }

    .content-item__name {
        color: var(--default_color);

        font-size: 13px;
        font-weight: 500;
    }

    .filter__buttons {
        display: flex;
        column-gap: 10px;

        height: 30px;
    }
</style>

<!-- пример фильтра 
    filterContent: {
                title: 'Фильтр по товарам',
                filters: [
                    {
                        title: 'Сортировать:',
                        type: 'radio',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            'По номеру (по возрастанию)',
                            'По номеру (по убыванию)',
                            'По возрастанию цены',
                            'По убыванию цены'
                        ]
                    },
                    {
                        title: 'Выбрать статус',
                        type: 'checkbox',
                        items: [
                            'Ожидает оплаты',
                            'Оформлен',
                            'Доставляется',
                            'Доставлен',
                            'Возврат',
                            'Архив'
                        ]
                    },
                    {
                        title: 'Выбрать статус',
                        type: 'checkbox',
                        items: [
                            'Ожидает оплаты',
                            'Ожидает оплаты'
                        ]
                    }
                ]
            }, -->