<template>
  <div class="input-form">
    <label class="input__title" for="">
      <slot name="title"></slot>
    </label>

    <div class="input__content" :style="styleInputForm">
      <input class="input"
             :pattern="pattern"
             :type="typeInput"
             :style="styleInput"
             :name="inputName"
             :value="modelValue"
             :placeholder="placeholder"
             ref="el_input"
             @input="updateInput">
      <svg class="input__svg" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.0751 0.0446824C13.13 0.0707281 13.8029 0.721463 14.5705 1.49079C15.8074 2.7306 15.9686 2.90323 15.9881 3.00982C16.0366 3.27372 16.0016 3.32131 15.0771 4.24969L14.2209 5.10954L12.5555 3.44466L10.8902 1.77977L11.7186 0.950621C12.214 0.454847 12.5974 0.0965548 12.6721 0.0594406C12.8219 -0.0148505 12.9405 -0.0191966 13.0751 0.0446824ZM11.8905 4.12466L13.5475 5.78185L9.65482 9.6746C7.51386 11.8156 5.74813 13.5673 5.73097 13.5673C5.6901 13.5673 2.43211 10.3097 2.43211 10.2688C2.43211 10.238 10.187 2.46746 10.2177 2.46746C10.2264 2.46746 10.9792 3.21318 11.8905 4.12466ZM4.90933 14.1337C4.85552 14.1832 0.618674 15.9788 0.516587 15.9954C0.243248 16.0398 -0.0400022 15.7549 0.00467874 15.4806C0.0149969 15.4171 0.418814 14.4436 0.90205 13.3172C1.38529 12.1908 1.80111 11.2205 1.82612 11.161L1.87159 11.0529L3.40181 12.5828C4.24346 13.4243 4.92183 14.1222 4.90933 14.1337Z"
              fill="#8EA2B0"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInputForm',
  props: {
    pattern:{
      type: String
    },
    styleInput: {
      type: String
    },
    placeholder: {
      type: String
    },
    inputName: {
      type: String
    },
    styleInputForm: {
      type: String
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number]
    }
  },
  methods: {
    updateInput(event) {
      let value = event.target.value;
      this.$emit('update:modelValue', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.input-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.input__title {
  color: var(--global_fourth_color);

  font-size: 12px;
}

.input__content {
  display: flex;
  align-items: center;

  position: relative;

  &:hover {
    .input__svg > * {
      fill: var(--default_background);
    }
  }

  // $if (.input = .input[type='tel']) {
  //     background: red;
  // }
}

.input {
  background: #EEF4F8;

  border: 1px solid #E4E7ED;
  border-radius: 6px;

  padding: 11px;
  padding-right: 28px;

  width: 100%;
  height: 40px;
  font-size: 12px;
  font-family: Roboto;

  &[type='email'] {
    text-decoration: underline;
  }

  // &[type='tel'] {

  // }
}

.input:invalid {
  border: 1px solid #e74a4a;
}

.input__svg {
  position: absolute;
  right: 12px;

  * {
    transition: 200ms;
  }
}
</style>