<template>
  <AppHeader v-if="shop" :shop="shop"></AppHeader>
  <main class="main" v-if="shop">
    <AppNavigationTop :shop="shop"/>
    <!-- tg.initData: {{tg.initData}} -->
    <router-view></router-view>
  </main>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import api from "@/api"
const currectId = 10
export default {
  name: 'App',
  components: {AppHeader},
  data() {
      return {
          shop_id: null,
          shop: undefined,
          tg: null,
      }
  },
  created() {
    document.title = "iVitrina лучший магазин"
    let sho = window.location.pathname.split("/")    
    let result = sho.findIndex(function (e) { return e == "shop"; });

    if (result<0) 
      window.location.replace(`/shop/${currectId}`)
    
    let shop_id = parseInt(sho[result+1])
    
    if (!Number.isInteger(shop_id))
        window.location.replace(`/shop/${currectId}`)
    
    this.getShopInfo(shop_id)

  },
  methods: {
       getShopInfo(shop_id) {
          api.getShop(shop_id).then((response) => {
            localStorage.setItem("shop_id",shop_id)
          
            this.shop = response.data.data
            localStorage.setItem("shop",JSON.stringify(this.shop))
            document.body.classList.add(this.shop.data.theme)
            window.Telegram.WebApp.expand() 
            this.tg = window.Telegram.WebApp
            this.authClient(this.shop.id)
                }).catch(() => window.location.replace(`/shop/${currectId}`))
      },
      async authClient(shop_id){
          let data = {client: this.tg.initData, shop_id:shop_id}
          const response = await  api.authClient(data)
          console.log(response.data.data)
      }
    }
}
</script>

<style lang="scss">
  @import '@/style/style.scss';
  #app {
    background: var(--global_body_background);
    min-height: 100vh;
    transition: var(--transition-default);
  }
  .main {
    position: relative;
    padding-bottom: 20px;
    max-height: 800px;
    overflow-y: auto;
  }
  // .main__button {
  //   border-radius: 50%;
  //   position: absolute;
  //   right: 17px;
  //   bottom: 8px;
  //   width: 40px;
  //   height: 40px;
  // }
</style>
