<template>
    <section class="cart">
        <div class="container" v-if="cart.products">
            
            <AppSectionTitle> 
                Товаров в корзине: {{cart.products.length }}  
                <!--
                    <span class="cart__table_clear" v-if="cart.products.length>0" @click="clearCart()"> Очистить корзину </span>     
                    В корзине {{cart.products.length }} наименования {{getCountName(cart.products.length)}}  
                 -->
                <div class="product__share">
                    <svg width="40" height="28" @click="goBack" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.07779 0.0847564C3.8368 0.204132 0.152167 3.89954 0.0652024 4.1091C-0.0184241 4.31053 -0.0182551 4.65045 0.0655404 4.8513C0.145279 5.04238 3.77739 8.70272 4.03554 8.85214C4.41606 9.07243 4.8022 9.02083 5.11512 8.70792C5.40956 8.41343 5.474 8.03599 5.2923 7.67059C5.25761 7.60087 4.74211 7.05352 4.14676 6.45423L3.0643 5.36463L8.09254 5.37824C13.1126 5.3918 13.1213 5.39197 13.4684 5.48447C14.8064 5.84104 15.7862 6.82985 16.1075 8.14772C16.2206 8.6117 16.2295 9.35119 16.1269 9.77622C15.8569 10.8957 15.1416 11.7724 14.1072 12.2515C13.3619 12.5968 13.973 12.5678 6.90901 12.5926C1.14605 12.6128 0.601992 12.6206 0.485955 12.6848C0.188973 12.8492 0 13.1585 0 13.4802C0 13.8535 0.160112 14.1231 0.485955 14.2985L0.654982 14.3894L6.63434 14.4022C12.9831 14.4156 13.2338 14.4093 13.974 14.2172C14.9174 13.9722 15.6387 13.5545 16.3762 12.8259C17.0687 12.1418 17.4655 11.514 17.7438 10.6624C18.1176 9.51832 18.0801 8.1178 17.6462 7.02258C17.0131 5.42489 15.6384 4.20286 13.9659 3.75114L13.4588 3.61414L8.26478 3.60235L3.07077 3.59056L4.17038 2.48242C5.37419 1.26935 5.39046 1.24627 5.35809 0.799152C5.3357 0.489493 5.17559 0.241741 4.90573 0.0992929C4.66576 -0.0273934 4.31659 -0.0335207 4.07779 0.0847564Z" fill="#8EA2B0"/>
                    </svg>
                </div>
            </AppSectionTitle> 
           <!--
            <div  class="cart__table_clear" @click="clearCart()">
                Очистить корзину
            </div>
            -->
           

            
            <div v-if="cart.info.sum" class="cart__content block-style">
                <div class="cart__table">
                    <div class="cart__table_names">
                        <div class="cart__table_name">
                            Фото
                        </div>
                        <div class="cart__table_name">
                            Товар
                        </div>
                        
                        <div class="cart__table_name">
                            Количество
                        </div>
                        <div class="cart__table_name">
                            Стоимость
                        </div>
                        <div class="cart__table_name">
                            Удалить
                        </div>
                    </div>
                    <ul class="cart__table_products cart__product" v-if="cart.products">
                        <li class="cart__table_product"
                        v-for="product in cart.products"
                        :key="product.id">
                            <div class="cart__product_image"
                                v-lazy-container="{ selector: 'img' }">
                                <AppImageLink v-if="product.image && product.type_id!=10"
                                    class="product__image"
                                    :stocks="product.stocks"
                                    :image="product.image.webp"/>
                                    
                                    <AppImageIiko v-if="product.image && product.type_id==10"
                                            class="product__image"
                                            :image="product.image"
                                    />
                            
                            </div>
                            <div class="cart__product_title">
                            <router-link  :to="{name:'PageProduct', params:{shop_id:shop_id, id:product.id}}">
                              {{product.name}}
                                <div v-if="product.items && product.items.length>0">
                                    <span style="font-size: 10px; display:block;" v-for="item in product.items"  :key="item.id">
                                        <span v-if="item.type=='counter'">{{ item.name }} - {{ item.counter  }} шт {{  item.counter*item.price }} руб</span>
                                        <span v-if="item.type=='ratio'">{{ item.name }}</span>
                                    </span>
                                </div>
                            </router-link>
                            <AppButton v-if="product.items && product.items.length>0"
                                     style=" padding: 7px 0; margin: 3px;"
                                    @click="editProductIiko(product)"
                                    >
                                изменить
                            </AppButton>
                            </div>
                            <AppInputCounterCart v-if="product.type_id!=3"
                                @update="updateCounter" 
                                :productId="product.id" 
                                :counter="product.counter"
                                :quantity="product.quantity"
                                >
                            </AppInputCounterCart>
                            <div  v-if="product.type_id==3"></div>
                            <AppProductPrice v-if="product" class="cart__product_price">
                                 {{ Number(product.price).toLocaleString() }} руб.
                            </AppProductPrice>
                            
                            <AppX class="cart__product_svg"  @click="deleteProductMethod(product.id)"></AppX>
                        </li>
                    </ul>
                    <div v-if="cart.products.length>0" class="cart__table_final">
                        <div class="cart__table_final_text" >
                            К оплате: 
                        </div>
                        <AppProductPrice>
                         {{ Math.ceil(cart.info.sum).toLocaleString() }}
                        </AppProductPrice>
                    </div>
                    <div class="cart__table_payment">
                        <div  class="cart__table_clear" @click="clearCart()">
                            Очистить корзину
                        </div>
                        <span v-if="cart.info.sum && cart.info.sum<10"> Минимальная сумма заказа 10 руб.</span>
                        <router-link :to="{name:'PageCartContact',   params: {shop_id:this.shop_id}}">
                            <AppButton v-if="cart.products.length>0 || !noPaymentSystem" class="cart__table_payment_button"
                            :styleButton="'_red'">
                                Оформить заказ
                            </AppButton>
                        </router-link>
                    </div>
                </div>
            </div>

            <AppMoneyRu v-if="shop_id" v-show="false"
                :shop_id="shop_id"
                @update="getCart"
                :element="element"
                :upCartSwith="upCartSwith"
            />
            <AppModifiers 
                v-if="show_modifiers"
                @close="show_modifiers=false"
                :product="editProduct"
                @updateCart="updateIikoCart"
            />
        </div>
    </section>
</template>

<script> 
import api from "@/api"
export default {
    name: 'PageCart',
    props: ['shop_id',"category_id"],
    data() {
        return {
            
            editProduct:{},
            show_modifiers:false,
            upCartSwith: false,
            allsum: 0,
            cart: {},
            noPaymentSystem: true,
            typePay: [],
        };
    },
    mounted(){
        this.getCart()
        this.getPyments();
    },
    methods: {
        async getPyments(){
            const response = await api.getPayments({shop_id:this.shop_id})
            this.typePay = response.data.data
            if (this.typePay.length>0) {  
            this.noPaymentSystem = false
            }
   
        },
        editProductIiko(product) {
            if (this.show_modifiers) {
                this.show_modifiers = false;
                return
            }
            
            this.editProduct = product
            if (product.items) {
                this.show_modifiers = true;
            }
            return 
        },
        updateIikoCart(element){
            this.element = element
            this.upCartSwith = !this.upCartSwith;
                this.show_modifiers=false
                console.log(element,"updateIikoCart")
            },  
        goBack() {

        if (this.category_id) {
        this.$router.push({name: "PageCatalogProducts", params: {back: true}})
        return
        } else
        this.$router.push({name: "PageMain", params: {back: true}})
        return
        },
        getuUpdateCart(cart){
            this.cart = cart
        },
        updateCounter(counter, productId){
            this.cart.products.find(item => item.id == productId).counter = counter
            this.element = {data: {id:productId, counter:counter}, type:'updateCounter'}
            this.upCartSwith = !this.upCartSwith
        },
        clearCart(){
            this.element = {data: {}, type:'clear'}
            this.upCartSwith = !this.upCartSwith
        },
        deleteProductMethod(productId){
          
            this.element = {data: {id:productId}, type:'delete'}
            this.upCartSwith = !this.upCartSwith
           // console.log(this.upCartSwith, 'deleteProductMethod')
        },
        updateCart(){
            this.allsum = this.sumFun(this.cart.products)
            localStorage.setItem('cart'+this.shop_id, JSON.stringify(this.cart))
            this.upCartSwith = !this.upCartSwith
         }, 
        getCart() {
            this.cart = JSON.parse(localStorage.getItem('cart'+this.shop_id)) ||  {products:[], info:{v:2}}
           console.log(this.cart.products)
            // if (this.cart.products.length>0)
           // console.log("getCart")
        }, 
        getCountName(count){
            let name = "наименование"
            switch(count) {
                case 1: 
                    name = "наименование"
                    break
                case 2:
                case 3:
                case 4:
                    name = "наименования"
                    break
                default:
                    name = "наименований"
                    break
                }
            return name
        },
    }
}
</script>

<style lang="scss" scoped>
    .product__share {
        margin-top: -20px;
        display: flex;
        align-items: flex-start;
        justify-content: right;
        flex: 1 1 50%;
        }
    .cart__content {
        padding: 14px;
        padding-bottom: 35px;
        
        position: relative;
        &::after {
            content: "";
            background: var(--global_block_line);
            position: absolute;
            left: 0;
            top: 50px;
            width: 100%;
            height: 1px;
        }
    }
    .cart__table_names {
        display: grid;
        grid-template-columns: 0.5fr 2fr 1fr 0.9fr 0.75fr;
        text-align: center;
        padding-top: 14px;
        padding-bottom: 25px;
        & > *:first-child {
            padding-right: 8px;
        }
        & > *:nth-child(-n+2) {
            text-align: left;
        }
        @media (max-width: 400px) {
            grid-template-columns: 0.5fr 1.25fr 1fr 0.75fr 0.25fr;
        }
    }
    .cart__table_name {
        color: var(--global_fourth_color);
        font-size: 11px;
        @media( max-width: 400px) {
            &:nth-last-child(2) {
                grid-column: -3/-1;
            }
 
            &:last-child {
                display: none;
            }
        }
    }
    .cart__table_products {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .cart__table_product {
        @extend .cart__table_names;
        align-items: center;
        border-bottom: 1px solid var(--global_block_line);
        padding-top: 0;
        padding-bottom: 10px;
        .counter {
            margin: 0 auto;
        }
    }
    .cart__product_title {
        color: var(--default_background);
    }
    .cart__product_svg {
        margin-left: auto;
    }
    .cart__product_img {
        width: 100%;
    }
    .cart__product_price {
        font-size: 12px;
        &::after {
            content: "";
        }
    }
    .cart__table_final {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 16px;
        & > * {
            color: var(--global_color);
            font-size: 16px;
            font-weight: 500;
        }
    }
    .cart__table_payment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cart__table_clear {
        color: var(--default_background);
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
    }
    .cart__table_payment_button {
        padding: 10px 0;
        width: 172px;
    }
</style>