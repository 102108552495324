import AppNavigationTop from '@/components/UI/AppNavigationTop';
import AppButton from '@/components/UI/AppButton';
import AppCatalogButtonLink from '@/components/UI/AppCatalogButtonLink';
import AppSliderBanner from '@/components/UI/AppSliderBanner';
import AppSearch from '@/components/UI/AppSearch';
import AppProductSearch from '@/components/UI/AppProductSearch';
import AppProducts from '@/components/UI/AppProducts';
import AppInputCounter from '@/components/UI/AppInputCounter';
import AppSectionTitle from '@/components/UI/AppSectionTitle';
import AppSlider from '@/components/UI/AppSlider';
import AppSliderAbout from '@/components/UI/AppSliderAbout';

import AppArrow from '@/components/UI/AppArrow';
import AppProductPrice from '@/components/UI/AppProductPrice';
import AppProductButton from '@/components/UI/AppProductButton';
import AppX from '@/components/UI/AppX';
import AppInputForm from '@/components/UI/AppInputForm';
import AppTextarea from '@/components/UI/AppTextarea';
import AppInputCheckbox from '@/components/UI/AppInputCheckbox';
import AppInput from '@/components/UI/AppInput';
import AppCheckbox from '@/components/UI/AppCheckbox';
import AppHref from '@/components/UI/AppHref';
import AppSelect from '@/components/UI/AppSelect';
import AppModal from '@/components/UI/AppModal';
import AppInputRadio from '@/components/UI/AppInputRadio';
import AppRadio from '@/components/UI/AppRadio';
import AppFilter from '@/components/UI/AppFilter';
import AppTitleSmall from '@/components/UI/AppTitleSmall';
import AppButtonRemove from '@/components/UI/AppButtonRemove';
import AppButtonEdit from '@/components/UI/AppButtonEdit';
import AppNavigationBottom from '@/components/UI/AppNavigationBottom';
import AppOrders from '@/components/UI/AppOrders';
import AppAppeals from '@/components/UI/AppAppeals';
import AppSearchAndFilter from '@/components/UI/AppSearchAndFilter';
import AppClients from '@/components/UI/AppClients';
import AppGreeting from '@/components/UI/AppGreeting';
import AppSettingNavigation from '@/components/UI/AppSettingNavigation';
import AppHelper from '@/components/UI/AppHelper';
import AppButtonSettingSave from '@/components/UI/AppButtonSettingSave';
import AppButtonDelete from '@/components/UI/AppButtonDelete';
import AppUploadImage from '@/components/UI/AppUploadImage';
import AppButtonAdd from '@/components/UI/AppButtonAdd';
import AppTitleSettingParam from '@/components/UI/AppTitleSettingParam';
import AppTextareaForm from '@/components/UI/AppTextareaForm';
import AppImageItems from '@/components/UI/AppImageItems';
import AppButtonArchive from '@/components/UI/AppButtonArchive';
import AppCartNavigationProgress from '@/components/UI/AppCartNavigationProgress';
import AppProductsShort from '@/components/UI/AppProductsShort';
import AppImage from '@/components/UI/AppImage';
import AppSelectSearch from '@/components/UI/AppSelectSearch';
import AppInputCounterCart from '@/components/UI/AppInputCounterCart';
import AppInputLock from '@/components/UI/AppInputLock';
import AppViewer from '@/components/UI/AppViewer';
import AppProductStateButton from '@/components/UI/AppProductStateButton';
import AppBasketBottomBanner from '@/components/UI/AppBasketBottomBanner';
import AppTelegramButton from "@/components/UI/AppTelegramButton";
import AppModalMessage from "@/components/UI/AppModalMessage";
import AppMoneyRu from "@/components/UI/AppMoneyRu";
import AppPopUpMultiPage from "@/components/UI/AppPopUpMultiPage";
import AppSelectorShow from "@/components/UI/AppSelectorShow";
import AppImageLink from "@/components/UI/AppImageLink";
import AppButtonExternal from '@/components/UI/AppButtonExternal';
import AppModifiers from '@/components/UI/AppModifiers';
import AppModifierButtons from '@/components/UI/AppModifierButtons';

import AppInputCounterModern from '@/components/UI/AppInputCounterModern';
import AppViewerSlider from "@/components/UI/AppViewerSlider"
import AppImageIiko from "@/components/UI/AppImageIiko"

import AppProductsIikoShort from "@/components/UI/AppProductsIikoShort"
import AppSliderIiko from "@/components/UI/AppSliderIiko"
export default [
    AppSliderIiko,
    AppProductsIikoShort,
    AppImageIiko,
    AppInputCounterModern,
    AppModifierButtons,
    AppModifiers,
    AppViewerSlider,
    AppButtonExternal,
    AppTelegramButton,
    AppSelectorShow,
    AppPopUpMultiPage,
    AppBasketBottomBanner,
    AppProductStateButton,
    AppNavigationTop,
    AppButton,
    AppCatalogButtonLink,
    AppSliderBanner,
    AppSearch,
    AppProductSearch,
    AppProducts,
    AppInputCounter,
    AppSectionTitle,
    AppSlider,
    AppSliderAbout,
    AppArrow,
    AppProductPrice,
    AppProductButton,
    AppX,
    AppInputForm,
    AppTextarea,
    AppInputCheckbox,
    AppInput,
    AppCheckbox,
    AppHref,
    AppSelect,
    AppModal,
    AppInputRadio,
    AppRadio,
    AppFilter,
    AppTitleSmall,
    AppButtonRemove,
    AppButtonEdit,
    AppNavigationBottom,
    AppOrders,
    AppAppeals,
    AppSearchAndFilter,
    AppClients,
    AppGreeting,
    AppSettingNavigation,
    AppHelper,
    AppButtonSettingSave,
    AppButtonDelete,
    AppUploadImage,
    AppButtonAdd,
    AppTitleSettingParam,
    AppTextareaForm,
    AppImageItems,
    AppButtonArchive,
    AppCartNavigationProgress,
    AppProductsShort,
    AppImage,
    AppSelectSearch,
    AppInputCounterCart,
    AppModalMessage,
    AppMoneyRu,
    AppInputLock,
    AppViewer,
    AppImageLink
];