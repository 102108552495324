<template>
    <div
    class="image"
    v-lazy-container="{ selector: 'img' }">
            <img
                draggable="false"
            v-if="image" 
            :data-src="image.url" 
            :alt="alt"> 
    </div>
</template>

<script>

export default {
    name: 'AppImageIiko',
    data() {
        return {
        }
    },
    props: {
      
        image: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: ''
        },
    },
    watch:{
        name(){
            console.log(this.name)
        },
        stock(){
            console.log(this.stock)
        }
    }
}
</script>

<style lang="scss" scoped>
    .image {
        padding-top: 100%;

        position: relative;

        img {
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;

            width: 90%;
            height: 90%;
        }

        &:hover {
            .image__delete {
                display: block;
            }
        }
    }

    .image__svg {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    .image__delete {
        display: none;

        position: absolute;
        top: 3px;
        right: 3px;
    }
</style>