<template>
    <section class="about">
        <div class="container" v-if="company">
            <h1>
               {{company.h1}}
            </h1>
            <br>
           <span class="p-text" v-html="company.h1_text"/>
           <br>
            <h2>
                {{company.h2}}
            </h2>
            <br>
            <span class="p-text" v-html="company.h2_text"/>
            <br>
            <h1>
               {{company.h3}}
            </h1>
            <br>
                <span class="p-text" v-html="company.h3_text"/>
            <br>
            <div class="slider">
                <h1>
                   {{company.photogallery_name}}
                </h1>
                <br>
                <div class="slider__content">
                    <AppSliderAbout v-if="company.images"
                    :format="'thumb'"
                    :slides="company.images"/>
                </div>
            </div>
        </div>
    </section>
</template>
 
<script>
import api from "../api"
export default {
    name: "PageAbout",
    props: ['shop_id'],
    data() {
        return {
            company: {},
           
        }
    },
    mounted(){
        //this.company = JSON.parse(localStorage.getItem('company'))||{};
        this.getCompany()
    },
    watch: { 
        shop_id(){

        }, 
    },
    methods:{
         async getCompany() {
            const response =  await api.getCompany({shop_id: this.shop_id})
            this.company =  response.data.data
            
        },
    }
}
</script>

<style lang="scss" scoped>
    .about {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .p-text p{
        margin-top: 5px;
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        font-family: Roboto;
        
    }

    .slider {
        h2 {
            margin-bottom: 8px;
        }
    }

    .slider__content {
        margin: 0 auto;

        width: 95%;
    }
</style>