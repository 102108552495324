<template>

    <div  class="card-modifiesrs" >
      
           <div class="product__top">
               <div class="product__top_text">
                   <AppSectionTitle style="margin-top:0px">
                   <h1>
                       <!-- Общая стоимость: {{ element.price }} руб-->
                        {{ select.name }}
                       <br>
                       <span style="color:red;"> {{ (showError)?"Сделайте выбор чтобы продолжить":"" }}</span>
                   </h1>
                   </AppSectionTitle>

                   
               </div>
               <div class="product__share">
                   
                   <svg width="25" style="cursor: pointer;" height="30" @click="close" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
               </svg>
               </div>
           </div>
       
           <div class="product-info__text" style=" overflow-y:auto;
                max-height: 300px;">
               <div  v-for="(item, i) in select.items" :key="i">
                   <AppModifierButtons
                       class="select-status "
                       :element="element"
                       @update="updateElement"
                       :item="item" 
                       :max="select.restrictions.maxQuantity"
                       :used="element.used"
                       :initiallyChecked="item.id === idOfItemToBeInitiallyChecked"
                       :selectCounter="item.selectCounter"
                       :checkedItemId="checkedItemId"
                       :checkedCheckBox="checkedCheckBox"
                   >
                       {{ item.price }} руб
                   </AppModifierButtons>     
               </div>
           </div>
           <div class="button-rule-container">
               <AppButton v-show="selectIndex>0"
                   :styleButton="'_white'"
                   style=" padding: 10px 0; margin: 4px;"
                   @click="back"
               >
               Назад
               </AppButton>
               <AppButton
                   :styleButton="'_white'"
                   style=" padding: 10px 0; margin: 4px;"
                   @click="next"
               >
               {{(selectIndex+1<this.elements.length)?"Далее":"Оформить"}}
               </AppButton>
           </div>
           <!--
           <AppModalMessage
                   :state="showError"
                   :message="'test'"
                   :showButton="true"
                   :buttonName="'Вернуться в корзину'"
                   @push="goCart()"
                   @close="showError = false"
           /> 
           -->

     </div>

</template>

<script> 
import api from '@/api'
export default {
 name: "AppModifiers",
 props: ['shop_id','product'],
 data() { 
   return {
        checkedCheckBox: false,
        checkedItemId: null,
       showError:false,
       element: this.product,
       elements: [],
       idOfItemToBeInitiallyChecked: false,
       select: {},
       selectIndex: 0

     }
 },
 watch:{
       "product.id"(){
           this.getModifiers()
       }
   },
   mounted() {
       if (this.product)
           this.getModifiers()
   },  
   methods:{
       close(){
           this.$emit('close')
       },
       async getModifiers(){
           // console.log(this.product,"product")
           this.showError=false

           this.element = this.product
            if (!this.element.items) 
                this.element.items = [],


           this.element.used= 0
           this.select={}
           this.selectIndex=0
           let res = await api.getProductModifiers(this.product.id) 
           this.elements = res.data.data
           this.select = this.elements[this.selectIndex]
           //Подставляем значение по умолчанию для 1 шага если есть
           if (this.element.items.length>0) {
               
                let find = this.element.items.find(item => item.groupId == this.select.id)
                //console.log(find.id, "this.element.items")
                if (find) this.idOfItemToBeInitiallyChecked = find.id
           }
         
        
       },
       back(){
           this.selectIndex--
           if ((this.selectIndex)<0) {
               this.selectIndex++
               return
           }
           this.select = this.elements[this.selectIndex]
           
       },
       next(){
           //проверка наличия в модификаторах 
           let group = this.elements[this.selectIndex]
           let find = this.element.items.find(item => item.groupId == group.id)
           if (!find && group.required) {
                this.showError = true
                return
           }
            

           //Оформить
           if (this.selectIndex+1>=this.elements.length) {
                  this.updateCart(this.element) 
                  return
           }
           this.selectIndex++
           if ((this.selectIndex)>=this.elements.length) {
               this.selectIndex--
               return
           }
           
           this.select = this.elements[this.selectIndex]
           this.select.restrictions.used = 0
           //Перебираем доступные элементы и подставляем значение
           let arr = this.element.items.filter(item => item.groupId == this.select.id)
           for (let index = 0; index < arr.length; index++) {
                this.select.restrictions.used = this.select.restrictions.used+arr[index].counter
                this.select.items.find(item => item.id == arr[index].id).counter = arr[index].counter
           }
           
       },
       /////////
       updateElement(elementId, counter){
            
           this.showError = false
           //Использовано едениц
           this.element.used = 0
           //Стартовая цена
           this.element.price = this.product.start_price
           // настройки group
           let group = this.elements[this.selectIndex]
           //Выделяем элемент
           if (group.type=="ratio")  this.checkedItemId = elementId
           //получаем выделенный элемент
           let select = this.elements[this.selectIndex].items.find(item => item.id == elementId)
           select.counter = counter
            //Находим все элементы группы
            if (! this.element.items)  this.element.items = []
            let result = this.element.items.filter(item => item.groupId == select.groupId)
       
            if (select.type=="checkbox") {
                    this.checkedCheckBox = counter
                    if ( this.checkedCheckBox){
                        this.element.items = this.element.items.filter(item => item.groupId !== select.groupId);
                        this.element.items.push(select)
                    }
                    else {
                        this.element.items = this.element.items.filter(item => item.groupId !== select.groupId);
                    }
            }
            //Если записи вообще нет
            if (result.length==0) {
               
                this.element.items.push(select)
            }
        
            //Если запись есть 
            //выясняем что в группе и 
            //решаем либо замена либо + либо удалить если counter=0
            if (result.length>0) {
                //только один элемент
                if (group.type=="ratio") {
                    //Определяем индекс элемента и заменяем
                    let index = this.element.items.findIndex(item => item.id == result[0].id)
                    this.element.items[index] = select
                    this.checkedItemId = elementId
                }
                //Несколько элементов 
                if (group.type=="counter") {
                    //Определяем был ли добавлен ранее и сразу находим индекс
                    let index = this.element.items.findIndex(item => item.id == select.id)
                    //Если найден //Удаляем если 0 или обновляем counter
                    if (index>-1) {
                        if (counter<1) this.element.items.splice(index,1)
                        else this.element.items[index] = select
                    }
                    else {
                        if (counter==0) return
                        this.element.items.push(select)
                    }
                }
            }
        
            for (let index = 0; index < this.element.items.length; index++) {
                //Общая цена
                this.element.price = Number(this.element.price) + (Number(this.element.items[index].counter) *Number(this.element.items[index].price))
                  //Ограничение по группе
                if (this.element.items[index].groupId==group.id)
                    this.element.used = this.element.used + this.element.items[index].counter
              
                
            }
          
       },
       updateCart(product){
        this.$emit('updateCart', product)
       },
   }
}
</script>
<style scoped>

.button-rule-container {
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-bottom: 40px;
}
.product__share {
 display: flex;
 align-items: flex-start;
 justify-content: right;
 flex: 1 1 50%;
}


.product__top {
 display: flex;
 align-items: flex-start;
 justify-content: space-between;

 margin-top: 18px;
 margin-bottom: 5px;
}

.product__top_text {
 display: flex;
 flex-direction: column;
 row-gap: 4px;
 width: 80%;
}

.product-info__text-container {
  

 display: flex;
 justify-content: space-between;
 align-items: center;


}

.product_stock {
 color: red;
 font-weight: bold;
}

.card-modifiesrs {
   max-height: 450px;
 position: fixed;
 bottom: 0;
 right: 0;
 left: 0;
 margin: auto;
 background: var(--default_background);
 z-index: 1;

 max-width: 90%;

 color: var(--default_color);
 padding: 8px 12px;
 border-radius: 8px 8px 0 0;
 align-items: center;
 justify-content: space-between;
}

</style>
