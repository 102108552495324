<template>
    <AppButton class="product-button" @click=addToCart()>
       
        {{title}}
       
    </AppButton> 
</template>

<script>
export default {
    name: 'AppProductStateButton',
    props:{
        productId: {
            type: [String, Number],
        },
        title: {
            type: String,
            default: "Заявка"
        }
       
    },
    methods: {
        addToCart(){
            this.$emit('update', this.productId)
        },
    }
}
</script>

<style lang="scss" scoped>
    .product-button {
        border-radius: 4px;

        display: flex;
        align-items: center;
        column-gap: 50%;

        padding: 7px;
        margin-left: 8px;

        width: 100%;

        @media (max-width: 522px) {
            align-items: center;
            height: 34px;
        }
    }

    .product-button_text {
        font-size: 12px;
        font-weight: 700;

        @media (max-width: 522px) {
            display: none;
        }
    }
</style>