<template>
  <section class="section-option-contact option-contact">
    <div class="container">
    
      <AppCartNavigationProgress :step="2" :shop_id="shop_id"></AppCartNavigationProgress>
      <AppSectionTitle class="option-contact__title" v-if="deliveries.length>0">
        Варианты доставки  
      </AppSectionTitle>
      <form  class="option-contact__form">
      
          <AppRadio v-for="(item,i) in deliveries"
                      :key="i" 
                      :forAndId="item.name"
                      v-model="select"
                      :name="'deliveryType'" 
          >
            {{ (item.title)?item.title:"" }} {{ getPrice(item) }}
          </AppRadio>
      </form>
      
      <br>
      <AppSectionTitle v-if="(select || digital_text) && (shop && !shop.iiko)" class="option-contact__title2">
     Условия доставки 
      </AppSectionTitle>
      <div  v-if="currentDescription!='null' && !shop.iiko" ref="demoText" class="demo-text" v-html="currentDescription || ''"/>
      

      <AppButton
          class="option-contact__button"
          :styleButton="(select || digital)? '_red active': '_red _no-active'"
          @click="handleClick()"
      >
        Далее
      </AppButton>

    </div>
  </section>
</template>

<script>
import api from "../../api"
//import AppCheckbox from "@/components/UI/AppCheckbox";
/*
const deliveries_Test = [
  {
    title: 'Самовывоз по городу',
    name: 'delivery_pickup',
    price: 0,
    description: 'Самовывоз по городу',
  },
  {
    title: 'Доставка по городу курьером',
    name: 'delivery_courier',
    price: 500,
    description: 'Доставка по городу курьером',
  },
  {
    title: 'Доставка по России',
    name: 'delivery_country',
    price: 800,
    description: 'Доставка по России',
  },
  {
    title: 'Доставка цифрового продукта',
    name: 'delivery_digital',
    price: 0,
    description: 'Доставка цифрового продукта',
  },
];
*/

export default {
  name: 'PageCartPlaceOption',
  //components: {AppCheckbox},
  props: ['shop_id'],
  data() {
    return {
      shop:JSON.parse(localStorage.getItem("shop")),
      cart: {},
      products: [],
      digital: false,
      digital_text: "",
      deliveries: [],
      select: false,
      delivery: null,
      selected: [],
      selectedDelivery: '',
      //currentDescription: '',
    }
  },
  computed:{
    currentDescription() {
      let result = this.deliveries.find(e=>e.name==this.select)
      if (result)
        return this.digital_text + result.description;
      return this.digital_text
    }
    
  },
  mounted() {
    this.getDeliveries()
  },
  methods: {
    /*
    selectItem() {
      console.log(i,val, "i")
      this.selected.forEach((itm, i, arr) => arr[i] = false)
      this.selected[i] = true;
      this.selectedDelivery = this.deliveries[i]
      console.log(this.deliveries[i])
      this.currentDescription = this.digital_text+ this.deliveries[i].description;
      this.select = true;
    },
    */
    getPrice(item) {
      if (item.name == 'delivery_courier' || item.name == 'delivery_iiko') {
        return ""
        /*
        if ((item.items[0].price))  return "от "+ item.items[0].price +" руб"
        else return "от 0 руб."
        */

      }
      return (item.price == 0 ? ": бесплатно" : ": " + item.price + " руб.")
    },

    handleClick() {
      if (this.digital) {
        localStorage.setItem('delivery', JSON.stringify("digital"))
        this.$router.push({name: 'PageCartComment', params: {shop_id: this.shop_id}})
        return;
      }
      if (!this.select) return;
      
      else localStorage.setItem('delivery', JSON.stringify(this.deliveries.find(e=>e.name==this.select)));
      this.$router.push({name: 'PageCartPlace', params: {shop_id: this.shop_id}})
    },
    async getDeliveries() {
      if (this.shop.iiko) {
        this.deliveries = [{id: 5,name: 'delivery_iiko',title:'Доставка курьером', price:0}]
        return
      }
      let cart_name = 'cart' + this.shop_id
      this.cart = JSON.parse(localStorage.getItem(cart_name)) || {}
      this.products = this.cart.products
      let types = []
      for (let index = 0; index < this.products.length; index++) types.push(this.products[index].type_id);
      this.digital = false;
      const response = await api.getDeliveries({shop_id: this.shop_id, types: types.join(", ")});
      let deliveries = response.data.data

      for (let index = 0; index < deliveries.length; index++) {
        if (deliveries[index].name == "delivery_digital") {
          if (deliveries.length == 1) this.digital = true
          this.digital_text =  deliveries[index].description

          deliveries.splice(index, 1)
        }
      }
      this.deliveries = deliveries
    }
  },
}
</script>

<style lang="scss" scoped>
.demo-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--global_color);
  min-height: 32px;
}

.option-contact__title {
  font-size: 14px;
  margin-bottom: 20px;
}

.option-contact__title2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--global_color_grey);
}

.option-contact__form {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  color: var(--global_color_grey);
  font-size: 14px;

  & > *:last-child {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.option-contact__button {
  font-size: 16px;
  padding: 10px 0;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 172px;
}

.option-contact__text-message {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  grid-column: 1/3;
  @media (max-width: 400px) {
    grid-column: 1;
  }
}

.option-contact__text-message_title {
  font-size: 12px;
  color: #7E7E7E;
}

.option-contact__text-message_textarea {
  padding: 11px;
  min-height: 82px;
}
</style>