import axios from 'axios'
const DOMEN = "https://mag.ivitrina.store/api/v1"
const API_ENDPOINT_PRODUCT = `${DOMEN}/v2/products/`

const API_ENDPOINT_DELIVERY=  `${DOMEN}/deliveries`
const  API_ENDPOINT_CITIES =  `${DOMEN}/cities`
const  API_ENDPOINT_STREETS =  `${DOMEN}/streets`

const  API_ENDPOINT_COUNTRIES=  `${DOMEN}/countries`
const API_ENDPOINT_CLIENT=  `${DOMEN}/peoples`
const API_ENDPOINT_SHOP = `${DOMEN}/shop/` 
const API_ENDPOINT_CATEGORIES = `${DOMEN}/categories/` 
const API_ENDPOINT_AUTH =  `${DOMEN}/auth/`  
const API_ENDPOINT_ORDER =  `${DOMEN}/orders` 
const API_ENDPOINT_PAYMENTS =  `${DOMEN}/payments`  
const API_ENDPOINT_FEEDBACK =  `${DOMEN}/feedback` 
const API_ENDPOINT_COPMANY =  `${DOMEN}/company` 


export default { 
    authClient(data) {
        return axios.post(API_ENDPOINT_AUTH, data)
    },
    //
    getProducts(data) {
        return axios.get(API_ENDPOINT_PRODUCT, {params: data})
    },
    getProductModifiers(product_id) {
        return axios.get(API_ENDPOINT_PRODUCT+product_id+'/modifiers')
    },
    getProduct(product_id) {
        return axios.get(API_ENDPOINT_PRODUCT+product_id)
    },
    getShop(shop_id) {
        return axios.get(API_ENDPOINT_SHOP+shop_id)
    },
    getCategories(shop_id) {
        return axios.get(API_ENDPOINT_CATEGORIES, {params:{shop_id: shop_id}})
    },
   
    
    //////////
    getClient(data) {
        return axios.get(API_ENDPOINT_CLIENT, {params: data})
    },
    createUpdateClient(data) {
        return axios.post(API_ENDPOINT_CLIENT, data)
    },
    getDeliveries(data) {
        return axios.get(API_ENDPOINT_DELIVERY, {params: data})
    },
    getPickups(data) {
        return axios.get(API_ENDPOINT_DELIVERY+"/pickups/"+data.id, {params: data})
    },
    getClientAddress(data) {
        return axios.get(API_ENDPOINT_DELIVERY+"/client_address/"+data.id, {params: data})
    },
    saveClientAddress(data) {
        return axios.post(API_ENDPOINT_DELIVERY+"/client_address", data)
    },
    getCompany(data) {
        return axios.get(API_ENDPOINT_COPMANY, {params: data})
    },
    getCities(data) {
        return axios.get(API_ENDPOINT_CITIES, {params: data})
    },
    getStreets(data) {
        return axios.get(API_ENDPOINT_STREETS, {params: data})
    },
    getCountries(data) {
        return axios.get(API_ENDPOINT_COUNTRIES, {params: data})
    },
    
    submit_order(data) {
        return axios.post(API_ENDPOINT_ORDER, data)
    },
    sendState(data) {
        return axios.post(API_ENDPOINT_ORDER+"/state", data)
    },
    sendFeedback(data) {
        return axios.post(API_ENDPOINT_FEEDBACK, data)
    },
    getPayments(data) {
        return axios.get(API_ENDPOINT_PAYMENTS, {params: data})
    },
    
   
}