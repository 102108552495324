<template>
  <teleport to="body">
    <div class="viewer-container">
      <div class="viewer">
        <div class="close-button"
             @click="sendClose"
        >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="19.799" width="28" height="4" transform="rotate(-45 0 19.799)" fill="#808080"/>
            <rect width="28" height="4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.6274 19.799)"
                  fill="#808080"/>
          </svg>
        </div>
        <picture>
          <source :srcset="img.url" :type="img.type">
        <img>
        </picture>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "AppViewer",
  data() {
    return {

    }
  },
  props: {
    img: {
      type: String,
    }
  },
  methods: {
    sendClose() {
      this.$emit('closeViewer')
    },
  }
}
</script>

<style scoped>
.viewer-container {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}

.viewer {
  overflow: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewer img {
  height: auto;
  width: 100%;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

</style>