 <template>
    <ul class="product__list">
        <li
        class="product__item"
        v-for="product in products"
        :key="product.id">
            <AppImageLink v-if="product.image"
                class="product__image"
                :stocks="product.stocks"
                :image="product.image.webp"/>
            
            <router-link  
            class="product__title"
            :to="`/catalog/product/${product.id}`">
                {{ product.name }}
            </router-link> 
    
            <AppProductPrice class="product__price">
                {{ product.sum }}
            </AppProductPrice>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AppProductsShort',
    props: {
        products: {
            type: Array,
            require: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .product__list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .product__item {
        display: grid;
        grid-template-columns: 0.15fr 0.5fr 0.5fr;
        align-items: center;
        column-gap: 10px;
    }

    .product__title {
        color: #20A0FF;

        font-size: 14px;
    }

    .product__price {
         font-size: 12px;
        margin-left: auto;
    }
</style>