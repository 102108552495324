<template>

    <label class="checkbox" :for="forAndId">
        <input
        class="checkbox__input"
        type="checkbox"
        :id="forAndId"
        @change="update(model)"
        v-model="model"
        hidden>
        <svg class="checkbox__svg" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.12671 9.84849C5.01721 9.97576 4.82781 9.98547 4.70584 9.87005L0.0950729 5.50611C-0.0268975 5.39068 -0.0322006 5.19647 0.0831582 5.0745L1.55217 3.52221C1.6676 3.40024 1.86182 3.39494 1.98379 3.5103L4.50247 5.89399C4.62444 6.00942 4.81383 5.99971 4.92334 5.87243L9.84396 0.155114C9.95346 0.0278406 10.1473 0.0133089 10.2745 0.122883L11.8942 1.51697C12.0214 1.62647 12.036 1.82028 11.9264 1.94755L5.12671 9.84849Z" fill="#20A0FF"/>
        </svg>
    </label>
</template> 

<script>
export default {
    name: 'AppInputCheckbox',
    props: {
        forAndId: {
            type: [String, Number],
            required: false
        },
        checked: {}
    },
    data() {
        return {
            model: true,
        };
    },
    mounted(){
        this.model = this.checked
    },
    methods:{
        update(model){
            this.$emit('update', model)
        }
    },
    watch: {
        checked(checked){
            this.model = checked
        }
    }
}
</script>

<style lang="scss" scoped>
    .checkbox {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 2px;

        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 20px;
        width: 20px;
        height: 20px;

        &:hover {
            border: 1px solid var(--default_background_hover);
        }

        &:active {
            border: 1px solid var(--default_background_active);
        }
    }

    .checkbox__input {
        width: 100%;
        height: 100%;

        &:checked + .checkbox__svg {
            opacity: 1;
        }
    }

    .checkbox__svg {
        opacity: 0;

        transition: 200ms;
        path {
            fill: var(--default_background);
        }
    }
</style>