import PageMain from '@/pages/PageMain';
import PageCatalog from '@/pages/catalog/PageCatalog';
import PageCatalogProducts from '@/pages/catalog/PageCatalogProducts'
import PageStockProducts from '@/pages/catalog/PageStockProducts'
import PageProduct from '@/pages/catalog/PageProduct';
import PageCart from '@/pages/cart/PageCart';
import PageCartContact from '@/pages/cart/PageCartContact';
import PageCartPlaceOption from '@/pages/cart/PageCartPlaceOption';
import PageCartPlace from '@/pages/cart/PageCartPlace';
import PageCartComment from '@/pages/cart/PageCartComment';
import PageCartPayment from '@/pages/cart/PageCartPayment';
import PageFeedback from '@/pages/PageFeedback';
import PageAbout from '@/pages/PageAbout';
import PageState from '@/pages//catalog/PageState';

import { createRouter, createWebHistory } from 'vue-router';




const routes = [
    {
        path: '/shop/:shop_id',
        component: PageMain,
        name: "PageMain",
        props: true,
    },
    {
        path: '/shop/:shop_id/page_state/:product_id',
        component: PageState,
        props: true,
        name: "PageState",
    },
    {
        path: '/shop/:shop_id/categories',
        component: PageCatalog,
        name: "PageCatalog",
        props: true,    
    }, 
    {
        path: '/shop/:shop_id/categories/:category_id',
        component: PageCatalogProducts,
        props: true,
        name: "PageCatalogProducts",
    },
    {
        path: '/shop/:shop_id/stocks/:stock_id',
        component: PageStockProducts,
        props: true,
        name: "PageStockProducts",
    },
    {
        path: '/shop/:shop_id/product/:id',
        component: PageProduct,
        props: true,
        name: "PageProduct",
    }, 
    {
        path: '/shop/:shop_id/cart/option',
        component: PageCartPlaceOption,
        props: true,
        name: "PageCartPlaceOption",
    },
    {
        path: '/shop/:shop_id/cart/place',
        component: PageCartPlace,
        props: true,
        name: "PageCartPlace",
    },
    {
        path: '/shop/:shop_id/cart/comment',
        component: PageCartComment,
        props: true,
        name: "PageCartComment",
    },
    {
        path: '/shop/:shop_id/cart/payment',
        component: PageCartPayment,
        props: true,
        name: "PageCartPayment",
    },
    {
        path: '/shop/:shop_id/cart/place',
        component: PageCartPlace,
        name: "PageCartPlace",
        props: true,

    },
    {
        path: '/shop/:shop_id/cart/option',
        component: PageCartPlaceOption,
        name: "PageCartPlaceOption",
        props: true,
    },
    {
        path: '/shop/:shop_id/cart/contact',
        component: PageCartContact,
        name: "PageCartContact",
        props: true,
    },
    {
        path: '/shop/:shop_id/cart',
        component: PageCart,
        name: "PageCart",
        props: true,
    },
   
    {
        path: '/shop/:shop_id/feedback',
        component: PageFeedback,
        name: "PageFeedback",
        props: true,
    },
    {
        path: '/shop/:shop_id/about',
        component: PageAbout,
        name: "PageAbout",
        props: true,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  
  
  export default router;