<template>
    <section class="section-main">
        <AppSliderBanner
            v-if="shop && stocks!=undefined"
            :shop_id="shop.id"
            :sliderBannerData="stocks"
        />
        <br>
        <div class="container"> 
            <AppCatalogButtonLink :shopId="shop_id" class="catalog-button-link"/>
            <AppProductSearch
                @sortProduct="sortProduct"
                v-if="shop"   
                :shop_id="shop.id" 
                :filterContent="filterContent
                ">
            </AppProductSearch> 
        </div>
        <div class="container">
            <AppProducts
                :sortItem="sortItem"
                v-if="shop_id"
                :shop_id = "shop_id"/>
        </div>
    </section>
</template> 
  
<script>
import api from "../api"
export default {
    name: 'PageMain',
    data() {
        return {
            sortItem:"",
            searchValue:"",
            shop: undefined,
            filterContent: {
                title: 'Сортировать:',
                filters: [
                    {
                        type: 'radio',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            {name:'id,desc', 'title':'Сначала новые'},
                            {name:'id,asc', 'title':'Сначала старые'},
                            {name:'price,asc', 'title':'По возрастанию цены'},
                            {name:'price,desc', 'title':'По убыванию цены'},
                        ]
                    }
                ]
            },
          
            sliderBannerData: []
        }
    },
    computed:{
      
    },
    mounted() {
        this.shop_id = localStorage.getItem('shop_id')
        this.getShopInfo()
    },
    methods: { 
        sortProduct(value){
            this.sortItem=value
        },
         async getShopInfo() {
            const response =  await api.getShop(this.shop_id)
            this.shop = response.data.data
            this.stocks = [];
            for (let index = 0; index < this.shop.stocks.length; index++) {
                if (this.shop.stocks[index].data.first){
                    this.stocks[0] = this.shop.stocks[index]
                    this.shop.stocks.splice(index,1)
                    break;
                }
            }
            for (let index = 0; index < this.shop.stocks.length; index++) {
                this.stocks.push(this.shop.stocks[index])
            }                
        },
         
    },
    watch:{
        shop_id(){
            if (this.shop_id) this.getShopInfo()
        }
    }
}
</script>

<style lang="scss">
    .container {
        padding: 0 16px;
    }

    .catalog-button-link {
        margin-bottom: 16px;
    }

    .product-search {
        margin-bottom: 13px;
    }
</style>