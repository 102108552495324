<template>
    <AppButton
    class="button-delete"
    :styleButton="'_red-external'">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8499 0.222448L13.707 2.0796L2.39331 13.3933L0.536157 11.5362L11.8499 0.222448ZM2.71114 0.288862L14.0248 11.6026L11.9276 13.6998L0.613907 2.38609L2.71114 0.288862Z" fill="#8EA2B0"/>
        </svg>
    </AppButton>
</template>

<script>
export default {
    name: 'AppButtonDelete'
}
</script>

<style lang="scss" scoped>
    .button-delete {
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
</style>