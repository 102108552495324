<template>
    <div class="product-search">
        <AppSearchAndFilter
        @updateModel="search"
        @sortProduct="sortProduct"
        :filterContent="filterContent"/>
        <div class="product-search__text" v-if="elements.length>0">
            <div class="product-search__maybe">
                Возможно, вы ищите:
            </div>
            <div 
                v-for="element in elements" 
                :key="element.id" 
                class="product-search__option">
                <router-link  class="product__title"
                    :to="getLink(element.id)">
                </router-link>
            </div>
        </div> 
    </div>
</template>

<script>
import api from "../../api"
export default {
    name: "AppProductSearch",
    props: {
      
        filterContent: {
            type: Object
        },
        shop_id: {
            type: [String, Number]
        },
    },
      data() {
        return {
           elements:[],
        }
    },
    methods:{
        getLink(id){
            return "/shop/"+this.shop_id+"/product/"+id
        },
        sortProduct(value){
             this.$emit('sortProduct', value)
        },
        search(value){
            if (!value) {this.elements = []; return;}
          this.getProducts(value)
        },
        async getProducts(value) {
            let data={
                shop_id: this.shop_id,
                search: value,
                search_check: true,
            }
            const response =  await api.getProducts(data)
            this.elements = response.data.data
        
        },
    }
}
</script>

<style lang="scss" scoped>
    .products__container {
        padding-bottom: 25px;

        @media (max-width: 400px) {
            padding-left: 9px;
            padding-right: 9px;
        }
    }

    .product-search {
        padding: 8px 0;

        position: relative;

        &::before {
            content: "";

            background: #E4E7ED;

            display: inline-block;

            position: absolute;
            left: -2%;
            
            bottom: 100%;

            width: 103%;
            height: 1px;
        }

        &::after {
            @extend ::before;

            top: 100%;
        }
    }

    .product-search__text {
        display: flex;
        flex-direction: column;
        row-gap: 3px;

        margin-top: 12px;
    }

    .product-search__maybe {
        color: #8EA2B0;

        font-size: 11px;
    }
    
    .product-search__option {
        color: var(--default_background);

                font-size: 12px;
        font-weight: 500;
    }
</style>