<template>
    <div> </div>
</template>

<script>
export default {
  name: 'AppTelegramButton',
   props: ['shop_id','addProduct',"upCartSwith","element"],
   data() {
        return {
            cart:{},

            theme: 'white',
        }
    },
    watch:{
        upCartSwith(){
            //console.log('upCartSwith')
            if (this.element){
                switch (this.element.type) {
                    case 'updateCounter':
                        
                        this.updateCounter(this.element.data)
                        break;
                    case 'clear':
                        this.ckearMethod()
                        break;
                    case 'delete':
                        this.deleteProductMethod(this.element.data)
                        break;
                        
                    default:
                        this.updateCart()
                        break;
                }
                
            }
            if (this.addProduct) {
                    //console.log(this.addProduct,'addProduct')
                    this.addProductMethod(this.addProduct)
            }
        }
    },
    mounted() {
        //console.log(this.shop_id, "window.Telegram shop_id")
        let route = this.$router
        let shop_id  = this.shop_id
        window.Telegram.WebApp.expand() 
        window.Telegram.WebApp.onEvent('mainButtonClicked', function(){
            route.push({ name: 'PageCart', params: { shop_id: shop_id}})
        }); 
        try{
            this.cart = JSON.parse(localStorage.getItem('cart'+this.shop_id)) || {products:[], info:{}}
        } catch(e){
            this.cart = {products:[], info:{}}
            localStorage.setItem('cart'+this.shop_id, JSON.stringify(this.cart))
        }
       

        //console.log(this.cart)
        this.updateButton()
    },  
    methods:{
        updateCounter(product){ 
            //console.log(product,'updateCounter')
            if (this.cart.products==undefined) this.cart = {products:[], info:{}}
            this.cart.products.find(item => item.id == product.id).data.counter = product.counter
            this.updateCart()
        },
        deleteProductMethod(product){
            if (this.cart.products==undefined) this.cart = {products:[], info:{}}
            let object_index = this.cart.products.findIndex(item => item.id == product.id)
            this.cart.products.splice(object_index,1)
            this.updateCart()

        },
        addProductMethod(product){
            if (this.cart.products==undefined) this.cart = {products:[], info:{}}
            let object_index = this.cart.products.findIndex(item => item.id == product.id)
            //not find
            if (object_index < 0)
            {
                this.cart.products.push({
                    id: product.id,
                    name: product.name,
                    image: (product.images.length>0)?product.images[0]:null,
                    type_id: product.type_id,
                    data: {
                        price: product.data.price,
                        shop_id: this.shop_id,
                        counter: product.data.counter,
                        sum: (product.data.counter * product.data.price)
                        }   
                    })
            }
            //find
            else {
                let new_counter = 1
                if (this.cart.products[object_index].type_id!=3)
                    new_counter = this.cart.products[object_index].data.counter + product.data.counter
                this.cart.products[object_index].data.counter = new_counter
                this.cart.products[object_index].data.sum = (new_counter * product.data.price)
            }
            this.updateCart()
        },
        updateCart(){
            let sum = 0
            let counter = 0
            for (let index = 0; index < this.cart.products.length; index++) {
                //console.log(this.cart.products[index])
                counter = counter + this.cart.products[index].data.counter
                this.cart.products[index].data.sum = this.cart.products[index].data.counter * this.cart.products[index].data.price
                sum = sum + this.cart.products[index].data.sum;
            }
            this.cart.info = {sum:sum, counter:counter}
            localStorage.setItem('cart'+this.shop_id, JSON.stringify(this.cart))
            this.$emit('update', this.cart)
            this.updateButton()
        },
        ckearMethod (){
            this.cart = {products:[], info:{}}
            localStorage.setItem('cart'+this.shop_id, JSON.stringify(this.cart))
            this.$emit('update', this.cart)
        },
        updateButton(){
            //console.log(this.$route)
            let tg = window.Telegram.WebApp
            if (!this.cart.products || this.cart.products.length < 1 || this.$route.name=="PageCart") {
                tg.MainButton.hide();
                return
            }
            setTimeout(function () {
                /*
                let button = document.getElementsByClassName('button')[0]
                let color = ""
                if (button)
                color = window.getComputedStyle(button).background
                */
                tg.MainButton.text = " " + this.cart.info.counter + " " + this.getCountName(this.cart.info.counter) + " на сумму " + this.cart.info.sum + " руб."
               // tg.MainButton.color = this.rgb2hex(color);
                tg.MainButton.show()
            }.bind(this), 200)
        },
        getCountName(count){
            let name = "товар"
            switch(count) {
                case 1: 
                    name = "товар"
                    break
                case 2:
                case 3:
                case 4:
                    name = "товарa"
                    break
                default:
                    name = "товаров"
                    break
                }
            return name
        },
        rgb2hex(rgb) {
            rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

            return (rgb && rgb.length === 4) ? "#" +
                ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
        },
    }
}

</script>
