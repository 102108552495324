<template>
    <div class="select-form">
        <label class="select__title" for="">
            <slot name="title"></slot>
        </label>
        <div class="select__content">
            <div class="select__block"
            @click="editShow()">
                <input class="select"  
                @click="update()"
                :value="getValue()" 
                disabled>
                <svg class="select__svg"
                :class="{'_rotate180': this.isShow}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31651 6.86825L6.38094 6.93268L11.6568 1.65681L10.4141 0.414061L5.92969 4.89844L1.51562 0.484375L0.343209 1.65679L5.93559 7.24917L6.31651 6.86825Z" fill="black"/>
                </svg>
            </div>
            <ul class="select__items" 
            :class="{'_show': this.isShow}">
                <li class="select_item"
                    v-for="item in items" 
                    :key="item.name"
                    @click="editSelectItem(item.id)">
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script> 
export default {
    name: 'AppSelect',
    data() {
        return {
            isShow: false,
            selectedItem: null
        }
    },
  
    props: {
        items: {
            type: Array,
            required: true       
        },
        modelValue: {
            type: [String, Number]
        }
    },
    computed: {
        model: function () {
            let f = this.modelValue
            return this.items.find(e=> e.id == f)
        }
    },
    methods: {
        check(){
            console.log('check')
        },
        getValue(){
            if (this.model==undefined) return ""
          return  this.model.name || this.items[0].name
        },
        editSelectItem(item) {
            this.editShow();
            this.$emit('update:modelValue', item);
            this.$emit('updateC');
           // return this.selectedItem  = item;
        }, 
        editShow() {
            return this.isShow = !this.isShow;
        }
    }
}
</script>
<style lang="scss" scoped>
    .select-form {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .select__title {
        color: #7E7E7E;

        font-size: 12px;
    }

    .select__content {
        position: relative;

        &:hover {
            .select__svg > * {
                fill: var(--default_background);
            }
        }
    }

    .select__block {
        display: flex;
        align-items: center;

        position: relative;
    }

    .select__items {
        max-height: 200px; 
        overflow: auto;
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        display: none;

        font-size: 12px;

        //position: absolute;

        width: 100%;

        z-index: 10;

        &._show {
            display: block;
        }
    }

    .select_item {
        border-bottom: 1px solid #E4E7ED;

        cursor: pointer;
        color: #000;
        padding: 11px;

        transition: 200ms;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: var(--default_background_hover);
            
            color: #FFFFFF;
        }

        &:active {
            background: var(--default_background_active);
        }
    }

    .select {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 6px;

        padding: 11px;
        padding-right: 28px;

        width: 100%;
    }

    .select__svg {
        position: absolute;
        right: 12px;

        transition: 200ms;

        * {
            transition: 200ms;
        }

        &._rotate180 {
            transform: rotate(180deg);
        }
    }
</style>