<template>
  <section class="section-cart-result-result cart-result-result">
    <div class="container">
      <AppCartNavigationProgress v-if="shop_id" :step="5" :shop_id="shop_id"></AppCartNavigationProgress>
      <div class="cart-result__content">
        <AppSectionTitle class="cart-result__title">
          Пожалуйста, проверьте Ваш заказ 
        </AppSectionTitle>

        <ul class="cart-result__user-info">
          <li class="cart-result__user-info_item">
            {{ client.fio }}
          </li>
          <li class="cart-result__user-info_item">
            {{ client.phone }}
          </li>
          <li class="cart-result__user-info_item">
            {{ client.email }}
          </li>
        </ul>


        <div class="cart-result__location">
          <AppTitleSmall class="cart-result__title-small">
            Условия доставки
          </AppTitleSmall>
          <span style="font-size: small;" v-if="delivery.delivery" >
              {{ delivery.delivery.title }}, {{ delivery_client.name }}
          </span>
          <span v-if="digital">
             Цифровой товар будет доставлен в Telegram
          </span>

        </div>
        <div  v-if="comment">
          <AppSectionTitle class="cart-result__title">
            Комментарий к заказу
          </AppSectionTitle>
          <div class="user-comment">
            {{ this.comment }}
          </div>
        </div>
        <div class="cart-result__product">
          <AppTitleSmall class="cart-result__title-small">
            Заказ
          </AppTitleSmall>
          
          <AppProductsShort  v-if="products[0] && products[0].type_id!=10"
              class="cart-result__products"
              :products="products"/>
             
              <AppProductsIikoShort v-if="products[0] && products[0].type_id==10"
              class="cart-result__products"
              :products="products"/>
   
          <div class="cart-result__delivery" v-if="delivery!='digital'">
            <AppTitleSmall class="cart-result__title-small">
              Доставка
            </AppTitleSmall>
            <AppProductPrice class="cart-result__delivery_price">
              {{ Number(delivery.price).toLocaleString() }}
            </AppProductPrice>
          </div>
        </div>
            
        <div class="cart-result__amount">
          <div class="cart-result__amount_text">
            Итого к оплате 
          </div>
          <AppProductPrice v-if="delivery" class="cart-result__amount_price">
            {{ sumFun(products, delivery) }}
          </AppProductPrice>
        </div>
        <div>
          <AppSectionTitle class="cart-result__title"  v-if="!noPaymentSystem">
            Выберите вариант оплаты 
          </AppSectionTitle>
         
          <AppSectionTitle class="cart-result__title"  v-if="noPaymentSystem">
            !!! Не подключена платежная система !!!
          </AppSectionTitle>
          <AppRadio
              class="select-status "
              v-for="(item, i) in typePay"
              :key="i"
              v-model="client.typePay"
              :forAndId="item.id"
              :name="'pay'" 
          >
            {{ item.name }}
          </AppRadio>
          <div  v-if="client.typePay=='check'">
            <AppSectionTitle class="cart-result__title">
              Для оплаты по счету введите данные:
            </AppSectionTitle>
            <AppRadio
              class="select-status "
              v-for="(item, i) in buyerStatus"
              :key="i" 
              v-model="client.typePay"
              :forAndId="item.name"
              :name="'buyerStatus'" 
            >
              {{ item.name }}
            </AppRadio>
            <div class="fields-container">
              <AppInputForm
                  class="buyer-status buyer-name"
                  style="color: rgba(0 0 0 /50%); margin-top: 26px"
                  :typeInput="'text'"
                  v-model="client.FCs">
                <template v-slot:title>
                  {{ buyerStatus[currentUserType][1] }}
                </template>
              </AppInputForm>
              <AppInputForm
                  class="buyer-status buyer-inn"
                  :typeInput="'text'"
                  v-model="client.inn">
                <template v-slot:title>
                  ИНН
                </template>
              </AppInputForm>
              <AppInputForm
                  v-if="currentUserType === 'ul'"
                  class="buyer-status buyer-kpp"
                  :typeInput="'text'"
                  v-model="client.kpp">
                <template v-slot:title>
                  КПП
                </template>
              </AppInputForm>
              <AppTextareaForm
                  class="buyer-status buyer-address"
                  v-model="client.address"
                  :placeholder="' '"
              >
                <template v-slot:title>
                  {{ buyerStatus[currentUserType][2] }}
                </template>
              </AppTextareaForm>
            </div>
          </div>
          <AppButton v-if="!noPaymentSystem || parse_int(sum)<1"
              @click="sendOrder()"
              class="cart-result__table_payment_button"
              :styleButton="'_red'">
            Оформить заказ 
          </AppButton>
        </div>
      </div>
      <AppModalMessage
            :state="showError"
            :message="text"
            :showButton="true"
            :buttonName="'Вернуться в корзину'"
            @push="goCart()"
            @close="showError = false"
      /> 
    </div>
  </section>
</template>

<script>
import api from "@/api"

export default {
  name: "PageCartPayment",
  components: {},

  props: ['shop_id'],
  data() {
    return {
      noPaymentSystem: false,
      showError: false,
      text:"",
      digital: false,
      sum: 0,
      client: {},
      delivery: {},
      delivery_client: {},
      cart: null,
      products: [],
      comment: "",
      currentUserType: 'fl',
      currentPayType: 'card',
      buyerStatus: {
        fl: ['Физ лицо', 'ФИО', 'Адрес регистрации'],
        ul: ['Юр лицо', 'Наименование организации', 'Юридический адрес'],
        ip: ['ИП', 'Наименование организации', 'Адрес регистрации ИП'],
        sz: ['Самозанятый', 'ФИО', 'Адрес регистрации'],
      },
      typePay: []
    }
  },
  
  mounted() {
    this.getPyments();
    let cart_name = 'cart'+this.shop_id
   // console.log(localStorage)
    this.client = JSON.parse(localStorage.getItem('client'))
    this.delivery = JSON.parse(localStorage.getItem('delivery'))
    console.log(this.delivery)
    this.delivery_client = JSON.parse(localStorage.getItem('delivery_client'))
    let cart = JSON.parse(localStorage.getItem(cart_name))
   
    this.products = cart.products
    let digitals = this.products.filter((e)=>e.type_id==3)
   
    this.digital = (digitals.length>0)?true:false
    console.log( this.digital," this.digital")
    this.comment = localStorage.getItem('comment')
   // this.client.typePay = "webMoney"
  },
  methods: {
    goCart(){
      this.$router.push({ name: 'PageCart', params: { shop_id: this.shop_id}})
    },
    sendOrder() {
      let cart_name = 'cart'+this.shop_id
      let initData = window.Telegram.WebApp.initData
      if (!initData) initData = 'query_id=AkkH3xsssddrgdAfAAPkcdeszBGag4ggg&user=%7B%22id%22%3A1711551479%2C%22first_name%22%3A%22G%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22G922816%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1658815162&hash=1f3213380c7e4d4024ca44258bc3812f014e9eab182dae613eb20b17e4186acf'
      let data = {
        "client": this.client,
        "delivery": (this.delivery=="digital")?{'digital':true}:this.delivery,
        "delivery_client": this.delivery_client,
        "products": this.products, 
        "initData": initData, 
        "comment": this.comment,
        "shop_id": this.shop_id,
        "type_id": (this.products[0].type_id||1)
      }
      api.submit_order(data).then((response) => {
            if (response.data.data) {
              localStorage.removeItem('delivery')
              localStorage.removeItem('delivery_client')
              localStorage.removeItem(cart_name)
              localStorage.removeItem('comment')
              window.Telegram.WebApp.close()
            }
          }).catch((error) => {
              if (error.response.data.error)
                this.showError = true
                this.text = error.response.data.data.error
                console.log(error.response.data.data,"error")
          }).finally(() => {
              console.log("finally")
          });
      console.log(cart_name)
    

    },
    async getPyments(){
        const response = await api.getPayments({shop_id:this.shop_id})
        this.typePay = response.data.data
        if (this.typePay.length==0) {  
          this.noPaymentSystem = true
        }
        this.client.typePay = this.typePay[0].id
    },
    sumFun(elements, delivery) {
      let sum = 0
      if (delivery.price) 
        sum = Number(delivery.price);
      for (let index = 0; index < elements.length; index++)
        sum = sum + Number(elements[index].sum)
        this.sum = sum
      return sum.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-result__title {
  font-size: 14px;
  margin-bottom: 19px;
}

.user-comment {
  font-size: 12px;
  margin-bottom: 19px;
  color: rgba(0, 0, 0, 0.7);
}

.fields-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.buyer-status {
  color: rgba(0 0 0 /50%);
  margin-top: 20px;
}

.buyer-inn, .buyer-kpp {
  max-width: 47%;
  min-width: 130px;
}

.buyer-name, .buyer-address {
  width: 100%;
}

.select-status {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.cart-result__title-small {
  font-size: 12px;
  color:rgba(0, 0, 0, 0.5);
}

.cart-result__user-info {
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  font-size: 12px;
  margin-bottom: 22px;
}

.cart-result__location {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  font-size: 14px;
  margin-bottom: 16px;
}

.cart-result__products {
  margin-top: 10px;
  margin-bottom: 13px;
}

.cart-result__delivery {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-result__delivery_price {
  font-size: 14px;
}

.cart-result__amount {
  border-top: 1px solid var(--global_block_line);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
  margin-top: 15px;
  margin-bottom: 22px;
}

.cart-result__amount_text {
  color: var(--global_fourth_color);
}

.cart-result__amount_price {
  color: var(--global_second_color);
  font-size: 18px;
}

.cart-result__table_payment_button {
  padding: 10px 0;
  margin: 0 auto;
  margin-top: 30px;
  width: 172px;
}
</style>