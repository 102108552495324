<template>
  <div class="pop-up-container"
       @click="close"
  >
  
    <div class="page-container"
         @click="toggleCountry = !toggleCountry"
    >
      <div class="close"
           @click="close"
      >
      </div>
      <div class="page page-1"
           v-if="currentPage === 1"
      >
        <AppSelectSearch
            v-model="selected.country_id"
            @search="searchCountry"
            :items="countries"
        > 
          <template v-slot:title>
            Страна 
          </template>
        </AppSelectSearch>
        <AppButton class="address-add__button"
                   :styleButton="selected.country_id ? '_red': '_red _no-active'"
                   @click="step2"
        >
          Далее
        </AppButton>
      </div>
      <div class="page page-2"
           v-if="currentPage === 2"
      >
  
      <AppSelectSearch
        v-model="selected.city_id"
            @search="searchCity" 
            :items="cities"
        >
          <template v-slot:title>
            Город*
          </template>
        </AppSelectSearch>
        <!--
        <span v-if="selected.city_id===-5" style="color:red; font-size:12px; margin-top:-5px">Населенный пункт отсутвует в списке. Он будет добавлен.</span>
          -->
        <div class="address-add__cities-index" v-if="shop_delivery_name=='delivery_country'">
          <AppInput
              v-model="selected.postcode"
              :typeInput="'integer'"
              class="address-add__index">
            <template v-slot:title>
              Индекс*
            </template>
          </AppInput>
        </div>
        <AppButton class="address-add__button-city"
                   :styleButton="((selected.city_id && this.selected.new_city.length>1) || selected.city_id) ? '_red': '_red _no-active'"
                   @click="nextStreet"
        >
          Далее 
        </AppButton>
      </div>
      <div class="page page-3"
           v-if="currentPage === 3"
      >
        <div class="address-add__street-house">
          <AppInput v-if="shop_delivery_name!='delivery_iiko'"
              v-model="selected.street"
              class="address-add__street">
            <template v-slot:title>
              Улица*
            </template>
          </AppInput>
          <AppSelectSearch v-if="shop_delivery_name=='delivery_iiko'"
            v-model="selected.street"
                @search="searchStreets" 
                :items="streets"
            >
              <template v-slot:title>
                Улица*
              </template>
          </AppSelectSearch>



          <AppInput
              v-model="selected.house"

              class="address-add__house">
            <template v-slot:title>
              Дом*
            </template>
          </AppInput>
        </div>
        <div class="address-add__home">
          <AppInput
              v-model="selected.building"
              class="address-add__apartment">
            <template v-slot:title>
              Корпус
            </template>
          </AppInput>
          <AppInput
              v-model="selected.home"
              class="address-add__apartment">
            <template v-slot:title>
              Квартира
            </template>
          </AppInput>
        </div>
        <AppButton class="address-add__button-home"
                   :styleButton="selected.street && selected.house ? '_red': '_red _no-active'"
                   @click="addAddress"
        >
          Далее
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "AppPopUpMultiPage",
  props: ["shop_delivery_name", "shop_id"],
  data() {
    return {
      countries: [],
      cities: [],
      streets:[],
      toggleCountry: true,
      
      currentPage: 1,
      selected: {
        country_id: 50,
        city_id: null,
        street: '',
        new_city: null,
        house: '',
        postcode: '',
        building: '',
        home: '',
      }
    }
  },
  mounted() {
      if (this.shop_delivery_name!="delivery_country") this.currentPage = 2
      
  
      this.getAddress()
      this.getCountries("россия")
    },
  methods: {
    step2(){
      if (!this.selected.country_id) return;
      this.currentPage=2
      this.getCities()
    },
    searchCity(name){
      this.getCities(name)
    },
    searchStreets(name){
      this.getStreets(name)
    },
    searchCountry(name){
      this.getCountries(name)
    },
    nextStreet() {
      if ((this.selected.city_id && this.selected.new_city.length>1) || this.selected.city_id) this.currentPage = 3;
    },
    close(event) {
      if (event.currentTarget === event.target) this.$emit('close'); 
    },
    addAddress() {
      
      if (this.selected.street && this.selected.house) {
        this.selected.street_name = this.streets.find(e => e.id == this.selected.street)
        this.$emit('saveAddress', this.selected);
      }
    }, 
    async getAddress() { 
      //console.log("getAddress");
      this.client = JSON.parse(localStorage.getItem('client'))
      const response = await api.getClientAddress({id: this.client.id, shop_id: this.shop_id})
      this.allAddress = response.data.data;
      if (this.shop_delivery_name == "delivery_country") this.address = Object.assign([], this.allAddress);
    },
    async getStreets(name="") {
      if (!this.selected.city_id) return
      const response = await api.getStreets({city_id: this.selected.city_id, people_id: this.client.id, "search":name, shop_id: this.shop_id, type:this.shop_delivery_name})
      this.streets = response.data.data
    },
    async getCities(name="") {
      this.selected.new_city = name
      //console.log(this.selected.country, "this.selected.country")
      const response = await api.getCities({country_id: this.selected.country_id, people_id: this.client.id, "search":name, shop_id: this.shop_id, type:this.shop_delivery_name})
      this.cities = response.data.data
      
      if (name.length>1 && this.cities.length<1) this.cities.push({id:-5, name:name})
      else this.selected.city_id = null
    },
    async getCountries(name) {
      let data = {"search":name}
      const response = await api.getCountries(data)
      this.countries = response.data.data
      this.selected.country = 50
      if (this.shop_delivery_name=="delivery_iiko")  this.getCities()
    },
   
  }
}

</script>

<style scoped>
.pop-up-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
}

.close {
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='19.7988' width='28' height='4' transform='rotate(-45 0 19.7988)' fill='black'/%3E%3Crect width='28' height='4' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 22.6274 19.7988)' fill='black'/%3E%3C/svg%3E");
  width: 23px;
  height: 23px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.address-add__street-house {
  display: flex;
  justify-content: space-between;
}

.address-add__street {
  width: 185px;
}

.address-add__house {
  width: 85px;
}

.address-add__apartment {
  width: 60px;
}

.address-add__home {
  display: flex;
  justify-content: space-between;
  max-width: 145px;
  margin-top: 20px;
}

.page-container {
  padding: 10px;
  position: relative;
  width: 310px;
  height: 360px;
  margin: auto;
  margin-top: 100px;
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.page {
  position: absolute;
  width: 100%;
  height: 330px;
  padding: 10px;
  border-radius: 8px;
  bottom: 0;
  left: 0;
}

.address-add__button {
  height: 40px;
  width: 100px;
  margin: auto;
  margin-top: 20%;
}

.address-add__button-city {
  height: 40px;
  width: 80px;
  margin: auto;
  margin-top: 20%;
}

.address-add__button-home {
  height: 40px;
  width: 80px;
  margin: auto;
  margin-top: 20%;
}

.address-add__cities-index {
  width: 105px;
  margin-top: 10px;
}

</style>