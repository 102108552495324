<template>
    <div class="radio_button" :class="{ dimmed: checkedCheckBox && item.type=='counter'}" @click="globClick">
      <div class="radio_div" v-if="item.type=='ratio'" >
        <div class="radio_button_text_info">
            <span style="color: black;">{{ item.name }} </span>
        </div>
        <label  class="radio">
            <input class="radio__input"
            type="radio"
            :checked="checked"
            name="'select'" 
            hidden>
            <svg class="radio__svg" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="#343434" stroke="#E4E7ED"/>
            </svg>
        </label>
      </div>
    
      <div class="radio_div" v-if="item.type=='checkbox'" >
          <div class="radio_button_text_info">
              <span style="color: black;">{{ item.name }} </span>
          </div>
          <label  class="radio">
              <input class="radio__input"
              type="radio"

              :checked="checkedCheckBox"
              name="'checkbox'" 
              hidden>
              <svg class="radio__svg" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="#343434" stroke="#E4E7ED"/>
            </svg>
          </label>
      </div>
      <div class="radio_div" v-if="item.type=='counter'" >
        <div class="radio_button_text_info">
            <span style="color: black;">{{ item.name }} </span>
        </div>
        <AppInputCounterModern 
          style="margin-top: 10px;"
          @update="updateCounter" 
          :counter="getCounter(item)"
          :max="max_counter"
          :min="0"
        />
      </div>
        <div class="radio__text">
            <slot></slot>
        </div>
    </div>
</template>

<script> 
export default {
    name: 'AppModifierButtons',
    props: {
      item: {
        type: Object,
        required: true
      },
      checkedItemId:{
        type: String,
      },
      checkedCheckBox: {
        type: Boolean,
        default: false
      },
      initiallyChecked: {
        type: Boolean,
        default: false
      },
      used: {
        type: Number,
        default:0
      },
      max: {
        type: Number,
        default:0
      },
      element: {
        type: Object,
        required: true
      },
    },
    data() { 
    return {
      
    }
  },
  mounted(){
   
  },  
  computed: {
    checked() {
      return this.item.id === this.checkedItemId;
    },
    // геттер вычисляемого значения
    max_counter: function () {
      return  this.max - this.used + this.item.counter
    },
  },
  methods: {
    globClick(){
      if (this.item.type=="ratio") this.setChecked()
      if (this.item.type=="checkbox") this.toggleChecked()
    },  
    toggleChecked() {
      
      this.$emit("update", this.item.id, this.checkedCheckBox ? false : true)
    },
    getCounter(item){
        
       return item.counter
    },
    setChecked() {
      this.$emit("update", this.item.id,  1)
    },
    updateCounter(value){
      if (this.checkedCheckBox) return
      this.counter = value
      this.$emit("update", this.item.id,  value)
    }
  },
  watch:{
    checkedCheckBox(checkedCheckBox){
       if (checkedCheckBox && this.item.type=='counter') this.$emit("update", this.item.id,  0)
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/style/vars';
  .dimmed {
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .radio, .checkbox {
      margin-top: 10px;
      background: #EEF4F8;
      border: 1px solid #E4E7ED;
      border-radius: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      width: 20px;
      height: 20px;
      &:hover {
          border: 1px solid var(--default_background_hover);
      }
      &:active {
          border: 1px solid var(--default_background_active);
      }
  }
  .checkbox__svg {
  opacity: 0;
  transition: 200ms;
}

  .checkbox__input:checked + .checkbox__svg {
    opacity: 1;
  }

  .radio__input {
      width: 100%;
      height: 100%;
      &:checked + .radio__svg {
          opacity: 1;
      }
  }
  .radio__svg {
      opacity: 0;
      transition: 200ms;
  }
  .radio_div{
    cursor: pointer;
    display: flex;
    align-items: center;  /* Центрирует элементы по вертикали */
    justify-content: space-between;  /* Располагает элементы равномерно по горизонтали */
  }
    .radio_button_text_info{
        display: flex;
    }
    .radio_button {
      font-size: 14px;
      padding: 5px 8px;
      border-radius: 10px;
      background: #fff;
      margin: 5px;
      border: #0000;
    }

    .radio__text {
        color: #7E7E7E;
        margin-top: -5px;
        font-size: 12px;
    }
</style>