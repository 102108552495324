<template>
  <div class="slider-banner"  
>

    <div
        class="slider-banner__arrow"
        :class="translateXPosition === 0 ? '_no-active' : null"
        @click="clickerLeftArrow">

      <div class="big_button_area">
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.25789 11.3921C6.70297 11.0212 6.76311 10.3597 6.39222 9.91465L3.13 5.99992L6.3922 2.08521C6.76309 1.64013 6.70295 0.978654 6.25787 0.607761C5.81279 0.236871 5.15132 0.297011 4.78043 0.742089L1.28896 4.93192C1.19137 4.97166 1.09815 5.0269 1.01288 5.09796C0.737555 5.32739 0.609531 5.66802 0.639722 5.99984C0.60947 6.33171 0.73749 6.67242 1.01286 6.90189C1.09815 6.97296 1.19138 7.02821 1.289 7.06795L4.78044 11.2578C5.15133 11.7028 5.81281 11.763 6.25789 11.3921Z"
                fill="white"/>
        </svg>
      </div>
    </div>
    <div v-if="sliderBannerData"
        class="slider-banner__content" 
        :style="{transform: 'translateX('+ translateXPosition + '%)', position: absolute, top: 0, bottom: 0, left: 0, right: 0}"
        >
       
          <div @click="goStock(slide)"  class="slider-banner__background" 
              v-for="slide in sliderBannerData"
              :key="slide.id"
              v-lazy:background-image="'https://mag.ivitrina.store/medias/full/'+slide.data.banner"
              >
              <!-- -->
            <div class="slider-banner__text">
 
          <br>
          <br>
          <br>
        </div>
      </div>
    </div>
    <div class="slider-banner__arrow slider-banner__arrow_right"
         :class="RightArrowLocked ? '_no-active' : null"
         @click="clickerRightArrow">
      <div class="big_button_area">
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.742112 11.3921C0.297033 11.0212 0.236893 10.3597 0.607784 9.91465L3.87 5.99992L0.6078 2.08521C0.236909 1.64013 0.297049 0.978654 0.742128 0.607761C1.18721 0.236871 1.84868 0.297011 2.21957 0.742089L5.71104 4.93192C5.80863 4.97166 5.90185 5.0269 5.98712 5.09796C6.26245 5.32739 6.39047 5.66802 6.36028 5.99984C6.39053 6.33171 6.26251 6.67242 5.98714 6.90189C5.90185 6.97296 5.80862 7.02821 5.711 7.06795L2.21956 11.2578C1.84867 11.7028 1.18719 11.763 0.742112 11.3921Z"
                fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppSliderBanner',
  data() {
    return {
      translateXPosition: 0,
      RightArrowLocked: false,
    }
  },
  props: {
    shop_id: {
      type: String,
      required: true
    },
    sliderBannerData: {
      type: Array,
      required: true
    }
  },
  methods: {
    goStock(stock) {
    let f = "/shop/" + this.shop_id + "/stocks/" + stock.id
    this.$router.push(f)
    },
    getStyleBanner(banner) {

      return {
        "background": "url('" + banner + "')",
        "backgroundSize": "cover",
        "width": "100%",

      }
    },
    clickerLeftArrow() {
      if (this.translateXPosition >= 0) {
        this.leftArrowLocked = true;
        return;
      }

      this.RightArrowLocked = false;
      return this.translateXPosition += 100;
    },
    clickerRightArrow() {
       console.log("clickerRightArrow")
      if (this.translateXPosition <= (-100 * (this.sliderBannerData.length - 1))) {

        return;
      }

      this.leftArrowLocked = false;
      if (this.translateXPosition <= (-100 * (this.sliderBannerData.length - 2))) {
        this.RightArrowLocked = true;
      }
      return this.translateXPosition -= 100;
    }
  },
  mounted(){
    console.log(this.sliderBannerData,"mounted")
  }
}
</script>

<style lang="scss" scoped>
.slider-banner {
  display: flex;
  align-items: center;

  position: relative;

  margin-bottom: 16px;

  overflow: hidden;
}

.slider-banner__content {
  display: flex;

  transition: 200ms;

  width: 100%;
}

.slider-banner__background {
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;

  flex: 1 0 100%;

  transition: inherit;

  padding: 5px;

  width: 100%;
}

.big_button_area {
  min-width: 50px;
  min-height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-banner__arrow {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 5px;
  transition: 200ms;
  min-width: 24px;
  width: 24px;
  height: 24px;

  z-index: 10;

  &:hover {
    background: var(--default_background);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--default_background);
  }

  &._no-active {
    background: rgba(0, 0, 0, 0.3);

    &:hover,
    &:active {
      background: rgba(0, 0, 0, 0.3);
    }

    svg {
      * {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.slider-banner__arrow_right {
  left: auto;
  right: 5px;
}

.slider-banner__text {
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  padding: 42px;
  padding-left: 52%;

  width: 20%;

  @media (max-width: 400px) {
    font-size: 18px;

    padding-top: 25px;
    padding-bottom: 25px;
  }
}
</style>