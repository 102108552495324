<template>
    <AppButton
    class="setting__button-save"
    :styleButton="'_red'">
        Сохранить
    </AppButton>
</template>
<script>
export default {
    name: 'AppButtonSettingSave'
}
</script>
<style lang="scss" scoped>
    .setting__button-save {
        margin: 0 auto;

        width: 172px;
        height: 40px;
    }
</style>