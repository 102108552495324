<template>
  <section class="section-cart-place cart-place">
    <div class="container">
      <AppCartNavigationProgress :step="3" :shop_id="shop_id"></AppCartNavigationProgress>
      <div class="cart-place__content">

        <div>

          <CartPlacePickup
              v-if="deliveryData.name=='delivery_pickup'"
              :shop_id="shop_id"
              :shop_delivery_id="deliveryData.id"
              @updateAddress="updateAddress"
          /> 
          <CartPlaceСourier style="margin-bottom:-35px" 
                            v-if="deliveryData.name=='delivery_courier'"
                            :shop_id="shop_id"
                            :shop_delivery_id="deliveryData.id"
                            @updateСourierAddress="updateСourierAddress"
          /> 
          <CartPlaceClientAddress
              v-if="(deliveryData.name=='delivery_courier'&& city_id) || deliveryData.name=='delivery_country' || deliveryData.name=='delivery_iiko'"
              :shop_id="shop_id"
              :city_id="city_id"
              :shop_delivery_id="deliveryData.id" 
              :shop_delivery_name="deliveryData.name"
              @updateAddress="updateAddress"
          />
          <br>
      
          <AppButton v-if="selectAddress"
              class="cart-place__button"
              :styleButton="showSave"
              @click="handleClick"
          >
            Далее
          </AppButton>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
function isTextInput(node) {
  return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
}

import CartPlaceClientAddress from './modules/CartPlaceClientAddress';
import CartPlacePickup from './modules/CartPlacePickup';
import CartPlaceСourier from './modules/CartPlaceСourier';
//import cities from '@/helpers/cities';
import countries from '@/helpers/countries';

export default {
  name: "PageCartPlace",
  props: ['shop_id'],
  components: {CartPlacePickup, CartPlaceClientAddress, CartPlaceСourier},
  data() {
    return {
      city_id: null,
      deliveryData: {},
      new_address: {
        country_id: 50
      },
      client: {},
      cities: [],
      reg: /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,24}))$/,
      countries,
      hiddenDigital: true,
      hiddenOther: false,
      showAddAddress: false,
      selectAddress: null,
      address: []
    };
  },
  computed: {
    showSave() {
      let class_st = '_red _no-active';
      if (!this.hiddenDigital) {
        if (this.reg.test(this.new_address.email)) {
          return '_red active';
        } else {
          return class_st
        }
      }
      if (!this.selectAddress) return class_st;
      return '_red active';
    },
    showSaveNewAddress() {
      let elements = ['country_id', 'city_id', 'street', 'home', 'flat']
      let class_st = '_red _no-active';
      for (let i = 0; i < elements.length; i++)
        if (!this.new_address[elements[i]]) return class_st;
      return '_red active';
    },
  },
  mounted() {
    this.deliveryData = JSON.parse(localStorage.getItem('delivery')) || {}
    if (this.deliveryData == "digital")
      this.$router.push({name: 'PageCartComment', params: {shop_id: this.shop_id}})
    this.client = JSON.parse(localStorage.getItem('client'))
    document.addEventListener('touchstart', function (e) {
      if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
 
        document.activeElement.blur();
      }
    }, false);
  },
  methods: {
    updateСourierAddress(data) {
      this.city_id = data.city_id
      let info = JSON.parse(localStorage.getItem('delivery'))
      info.price = data.price
      info.title = info.title + " " + data.name
      localStorage.setItem('delivery', JSON.stringify(info))
    },
    updateAddress(data) {
      localStorage.setItem('delivery_client', JSON.stringify(data))
      this.selectAddress = data
    },
    handleClick() {
      if (!this.selectAddress) return
      this.$router.push({name: 'PageCartComment', params: {shop_id: this.shop_id}})
    },
  }
}

</script>

<style lang="scss" scoped>

.cart-place__small_title {
  color: #7E7E7E;
  font-size: 12px;
}

.cart-place__select-block {
  border-bottom: 1px solid #E4E7ED;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.cart-place__add {
  margin-bottom: 16px;
}

.address-add {
  display: flex;
  flex-direction: column;
  row-gap: 21px;
  margin-bottom: 20px;
}

.address-add__cities-index {
  display: flex;
  column-gap: 18px;
}

.address-add__cities {
  flex: 1 1 auto;
}

.address-add__index {
  flex: 0 0 35%;
}

.address-email {
  flex: 0 0 100%;
  margin-bottom: 20px;
}

.address-add__street-house {
  display: flex;
  column-gap: 18px;
}

.address-add__street {
  flex: 1 1 auto;
}

.address-add__house {
  flex: 0 0 25%;
}

.address-add__bottom {
  display: flex;
  align-items: flex-end;
  column-gap: 16px;

  .address-add__button {
    margin: 0;
  }
}

.address-add__apartment {
  flex: 1 1 100%;
}

.cart-place__button {
  padding: 10px 0;
  margin: 0 auto;
  width: 172px;
}
</style>