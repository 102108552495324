<template>
  <teleport to="body">
    <div class="viewer-container">
      <div class="viewer">
        <div class="close-button"
             @click="sendClose"
        >
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#292D32"/>
            <path d="M13.0594 12.0001L15.3594 9.70011C15.6494 9.41011 15.6494 8.93011 15.3594 8.64011C15.0694 8.35011 14.5894 8.35011 14.2994 8.64011L11.9994 10.9401L9.69937 8.64011C9.40937 8.35011 8.92937 8.35011 8.63938 8.64011C8.34938 8.93011 8.34938 9.41011 8.63938 9.70011L10.9394 12.0001L8.63938 14.3001C8.34938 14.5901 8.34938 15.0701 8.63938 15.3601C8.78938 15.5101 8.97937 15.5801 9.16937 15.5801C9.35937 15.5801 9.54937 15.5101 9.69937 15.3601L11.9994 13.0601L14.2994 15.3601C14.4494 15.5101 14.6394 15.5801 14.8294 15.5801C15.0194 15.5801 15.2094 15.5101 15.3594 15.3601C15.6494 15.0701 15.6494 14.5901 15.3594 14.3001L13.0594 12.0001Z" fill="white"/>
          </svg>
        </div>
        <div class="slider__arrow_left" v-if="imgs.length>1" @click="clickerLeftArrow()">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#292D32"/>
            <path d="M13.2599 16.2802C13.0699 16.2802 12.8799 16.2102 12.7299 16.0602L9.19992 12.5302C8.90992 12.2402 8.90992 11.7602 9.19992 11.4702L12.7299 7.94016C13.0199 7.65016 13.4999 7.65016 13.7899 7.94016C14.0799 8.23016 14.0799 8.71016 13.7899 9.00016L10.7899 12.0002L13.7899 15.0002C14.0799 15.2902 14.0799 15.7702 13.7899 16.0602C13.6499 16.2102 13.4599 16.2802 13.2599 16.2802Z" fill="white"/>
          </svg>
        </div>
        <div class="slider__arrow_right" v-if="imgs.length>1" @click="clickerRightArrow()">
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#292D32"/>
            <path d="M10.7397 16.2802C10.5497 16.2802 10.3597 16.2102 10.2097 16.0602C9.91969 15.7702 9.91969 15.2902 10.2097 15.0002L13.2097 12.0002L10.2097 9.00016C9.91969 8.71016 9.91969 8.23016 10.2097 7.94016C10.4997 7.65016 10.9797 7.65016 11.2697 7.94016L14.7997 11.4702C15.0897 11.7602 15.0897 12.2402 14.7997 12.5302L11.2697 16.0602C11.1197 16.2102 10.9297 16.2802 10.7397 16.2802Z" fill="white"/>
          </svg>
        </div>
        <div class="slider__content_item_inner" v-if="imgs">
          
          <ul class="slider__content"
          :style="{transform: 'translateX('+ translateXPosition + '%)'}">
        <li
              class="slider__content_item"
              v-for="img in imgs"
              :key="img.id">
              <picture>
                <source :srcset="img.url" >
              <img>
              </picture>
          </li>
        </ul>
             
        </div> 
     
       
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "AppViewerSlider",
  data() {
    return {
      translateXPosition: 0,
    }
  },
  props: {
    imgs: {
      type: Array,
      required: true
    }
  },
  mounted(){
    this.addSwipe();
    this.addSwipeMob();
  },
  methods: {
    sendClose() {
      this.$emit('closeViewer')
    },
    clickerLeftArrow() {
      if (this.translateXPosition >= 0) {
        this.leftArrowLocked = true;
        return;
      }
      this.RightArrowLocked = false;
      this.currectImageIndex-- 
      return this.translateXPosition += 100;
    },
    clickerRightArrow() {
      if (this.translateXPosition <= (-100 * (this.imgs.length - 1))) {
        this.RightArrowLocked = true;
        return;
      }
      this.leftArrowLocked = false;
      this.currectImageIndex++
      return this.translateXPosition -= 100;
    },
    addSwipe() {
      let setCurrentImgUrl = this.setCurrentImgUrl
      let start, end, ctx = this;
      let slide = document.querySelector('.slider__content_item_inner');
      slide.addEventListener('mousedown', function (event) {
        //console.log("event.clientX");
        start = event.clientX;
        slide.addEventListener('mouseup', function (event) {
          //console.log(event.clientX);
          end = event.clientX;
          let diff = start - end;
          if (Math.abs(diff)<20) {
            setCurrentImgUrl(event)
            //ctx.$emit('showImg', event);
          } else {
            diff>0?ctx.clickerRightArrow():ctx.clickerLeftArrow();
          }
        }, {once: true})
      })
    },
    addSwipeMob() {
      let start, end, ctx = this;
      let slide = document.querySelector('.slider__content_item_inner');
      slide.addEventListener('touchstart', function (event) {
        let clientX = event.touches[0].clientX;
        //console.log(clientX);
        start = clientX;
        slide.addEventListener('touchend', function (event) {
          let clientX = event.changedTouches[0].clientX;
          //console.log(clientX);
          end = clientX;
          let diff = start - end;
          if (Math.abs(diff)<20) {
            ctx.$emit('showImg', event);
          } else {
            diff>0?ctx.clickerRightArrow():ctx.clickerLeftArrow();
          }
        }, {once: true})
      })
    },
  }
}
</script>

<style scoped>

.slider__content {
  display: flex;
  position: relative;
  transition: 300ms;
  width: 100%;
}
.slider__content_item {
  margin-top: 5px;
  justify-content: center;
  flex: 0 0 100%;
}
.slider__content_item_inner {
  width: 100%;
  cursor: pointer;
}
.slider__content_video {
  
  position: relative;
  padding-top: 100%;
  .slider__content_video-mp4 {

    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.slider__content_img {
  @extend .slider__content_video;
}
.viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}

.viewer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewer img {
  height: auto;
  width: 100%;
}

.close-button {
  z-index: 999;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.slider__arrow_left {
  z-index: 999;
  position: absolute;
  left: 5px;
  cursor: pointer;
}
.slider__arrow_right {
  z-index: 999;
  position: absolute;
  right: 5px;
  cursor: pointer;
}

</style>