<template>
    <div class="search-filter">
        <AppSearch @updateModel="search" class="search-filter__input"></AppSearch>
        <AppFilter
            @goSort="sortProduct"
            :filterContent="filterContent"
            class="search-filter__filter">
        </AppFilter>
    </div>
</template> 

<script>
export default {
    name: 'AppSearchAndFilter',
    props: {
        filterContent: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    methods:{
        search(value){
            this.$emit('updateModel', value)
   
        },
        sortProduct(value){
             this.$emit('sortProduct', value)
        }
    }
}
</script>

<style lang="scss" scoped>
    .search-filter {
        display: flex;
        column-gap: 10px;
    }

    .search-filter__input {
        flex: 1 1 auto;
    }
</style>