<template>
    <div class="clients-table clients__container">
        <div class="clients-table__content">
            <ul class="clients">
                <li class="client"
                v-for="client in clients"
                :key="client.id">
                    <ul class="client__short-info">
                        <li class="client__short-info_item">
                            <AppTitleSmall>
                                ID
                            </AppTitleSmall>
                            <div class="client__statistics_item-text">
                                {{ client.id }}
                            </div>
                        </li>
                        <li class="client__short-info_item client__short-info_user">
                            <AppTitleSmall>
                                Имя клиента/логин
                            </AppTitleSmall>
                            <div class="client__user">
                                {{ client.user }}
                            </div>
                        </li>
                    </ul>
                    <ul class="client__statistics">
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Покупки
                            </AppTitleSmall>
                            <div class="client__statistics_item-text">
                                {{ client.count }}
                            </div>
                        </li>
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Сумма выкупа
                            </AppTitleSmall>
                            <AppProductPrice class="client__statistics_item-text">
                                {{ client.amount }}
                            </AppProductPrice>
                        </li>
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Средний чек
                            </AppTitleSmall>
                            <AppProductPrice class="client__statistics_item-text">
                                {{ client.amount_avg }}
                            </AppProductPrice>
                        </li>
                    </ul>
                    <div class="client__interaction">
                        <router-link
                        :to="`/client/${client.id}`">
                            <AppButton class="client__interaction_button"
                            :styleButton="'_blue-external'">
                                Подробнее
                            </AppButton>
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>
        <AppNavigationBottom class="clients-table__buttons"></AppNavigationBottom>
    </div>
</template>

<script>
export default {
    name: "AppClients",
    props: {
        clients: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .clients-table__content {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        padding: 11px 13px;
        margin-bottom: 14px;
    }

    .clients {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .client {
        position: relative;

        padding-top: 8px;

        &::before {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            top: 0;

            height: 2px;
            width: 100%;
        }

        &:first-child {
            padding-top: 0;

            &::before {
                background: none;

                width: 0;
                height: 0;
            }
        }
    }
    .client__short-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .client__short-info_item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    .client__short-info_user {
        grid-column: 2/4;
    }

    .client__statistics {
        border-top: 1px solid #E4E7ED;
        border-bottom: 1px solid #E4E7ED;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        padding: 9px 0;
        margin: 9px 0;
    }

    .client__statistics_item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    .client__user {
        color: rgba(0, 0, 0, 0.75);

        font-size: 14px;
    }

    .client__statistics_item-text {
        color: rgba(0, 0, 0, 0.75);

        font-size: 12px;
        font-weight: 400;
    }

    .client__interaction_button {
        width: 94px;
        height: 30px;
    }
</style>