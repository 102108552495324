<template>
  <div>
    <br>
    <AppSectionTitle>
      Адрес доставки 
    </AppSectionTitle>
    <div class="cart-place__select-block" v-if="address && address.length>0">
      <AppSelect
          v-model="selectAddress"
          :items="address"/>
    </div>
    <AppButtonAdd class="cart-place__add"
                  @click="showPopUp = true">
      Добавить новый адрес 
    </AppButtonAdd> 
    <AppPopUpMultiPage
        v-if="showPopUp"
        :shop_delivery_name="shop_delivery_name"
        :shop_id="shop_id"
        @close="showPopUp = false"
        @saveAddress="saveAddress"  
    />
  </div>
</template>

<script>
import api from "@/api"

export default {
  name: 'CartPlaceClientAddress',
  props: ['shop_id', "shop_delivery_id", "city_id", "shop_delivery_name"],
  data() {
    return {
      allAddress: [],
      new_address: {},
      showAddAddress: false,
      cities: [],
      selectAddress: null,
      address: [],
      client: {},
      showPopUp: false
    };
  },
  computed: {
    showSaveNewAddress() {
      let elements = ['city_id', 'street', 'house']
      let class_st = '_red _no-active';
      for (let i = 0; i < elements.length; i++)
        if (!this.new_address[elements[i]]) return class_st;
      return '_red active';
    },
  },
  mounted() {
    this.getAddress()
    this.getCities(50) 
  },
  methods: {
    async saveAddress(new_address) {
      new_address.people_id = this.client.id
      const response = await api.saveClientAddress(new_address)
      let address = response.data.data
      this.allAddress.push(address)
      this.selectAddress = address.id
     
      this.showPopUp = false
      this.filterAddress(this.city_id)
    },
    async getAddress() {
     
      this.client = JSON.parse(localStorage.getItem('client'))
      const response = await api.getClientAddress({id: this.client.id, shop_id: this.shop_id, type:this.shop_delivery_name})
      this.allAddress = response.data.data
      this.selectAddress = this.allAddress[this.allAddress.length-1].id
      //Если доставка по стране
      if (this.shop_delivery_name == "delivery_country")
        this.address = Object.assign([], this.allAddress)
      else this.filterAddress(this.city_id)
     
    },
    filterAddress(city_id){
      city_id
      /*
      if (city_id)
        this.address = Object.assign([], this.allAddress.filter(e => e.city_id == city_id))
      else
      */
        this.address = Object.assign([], this.allAddress)
    },
    async getCities(country_id) {
      const response = await api.getCities({country_id: country_id, people_id: this.client.id,type: this.shop_delivery_name, shop_id: this.shop_id})
      this.cities = response.data.data
    },
  },
  watch: {
    shop_delivery_id() {
      this.getCities(50)
      this.getAddress()
    },
    city_id(city_id) {
      this.filterAddress(city_id)

    },
    selectAddress() {
      this.$emit('updateAddress', this.address.find(e => e.id == this.selectAddress))
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-place__small_title {
  color: #7E7E7E;
  font-size: 12px;
}

.cart-place__select-block {
  border-bottom: 1px solid #E4E7ED;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.cart-place__add {
  margin-bottom: 16px;
}

.address-add {
  display: flex;
  flex-direction: column;
  row-gap: 21px;
  margin-bottom: 20px;
}

.address-add__cities-index {
  display: flex;
  column-gap: 18px;
}

.address-add__cities {
  flex: 1 1 auto;
}

.address-add__index {
  flex: 0 0 35%;
}

.address-email {
  flex: 0 0 100%;
  margin-bottom: 20px;
}

.address-add__street-house {
  display: flex;
  column-gap: 18px;
}

.address-add__street {
  flex: 1 1 auto;
}

.address-add__house {
  flex: 0 0 25%;
}

.address-add__bottom {
  display: flex;
  align-items: flex-end;
  column-gap: 16px;

  .address-add__button {
    margin: 0;
  }
}

.address-add__apartment {
  flex: 1 1 100%;
}

.cart-place__button {
  padding: 10px 0;
  margin: 0 auto;
  width: 172px;
}
</style>