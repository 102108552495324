<template>
    <div class="section-catalog">
        <div class="container">
            <div class="catalog__top">
                <AppSectionTitle>
                      {{category_name}}
                </AppSectionTitle>
                <AppFilter
                @goSort="sortProduct"
                :filterContent="filterContent"></AppFilter>
            </div>
            <AppProducts
            :sortItem="sortItem"
            v-if="shop_id!=undefined"
            :shop_id="shop_id"
            :category_id="category_id"/>
        </div>
    </div> 
</template>

<script>
export default {
    name: 'PageCatalogProducts',
    props: ['shop_id', 'category_id', 'category_name'],
    data() {
        return {
            products: [],
            sortItem:"",
             filterContent: {
                title: 'Сортировать:',
                filters: [
                    {
                        type: 'radio',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            {name:'id,desc', 'title':'Сначала новые'},
                            {name:'id,asc', 'title':'Сначала старые'},
                            {name:'price,asc', 'title':'По возрастанию цены'},
                            {name:'price,desc', 'title':'По убыванию цены'},
                        ]
                    }
                ]
            },
        }
    }, 
    mounted() {
       
    },
    methods: {
         sortProduct(value){
            this.sortItem=value
            console.log(value)
        },
    }  
}
</script>

<style lang="scss" scoped>
    .section-catalog {
        padding-bottom: 17px;
    }

    .catalog__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>