<template>
    <section class="section-cart-comment cart-comment">
        <div class="container">
            <AppCartNavigationProgress :step="4" :shop_id="shop_id"></AppCartNavigationProgress>
            <form class="cart-comment__form" v-on:submit.prevent>
                <div class="cart-comment__block-textarea">
                    <div class="cart-comment__block-textarea_title">
                        Оставить комментарий к заказу 
                    </div>
                    <AppTextareaForm :placeholder="'Ваши пожелания к заказу. Время доставки, если необходимо. ФИО получателя заказ.'" v-model="comment" class="cart-comment__textarea"></AppTextareaForm>
                </div>
               <AppCheckbox class="cart-contact__consent" :forAndId="'consent'"  v-model="personal">
                    Я даю согласие на <AppHref  @click="golink()">обработку моих персональных данных</AppHref> 
                </AppCheckbox>
                <AppCheckbox class="cart-contact__consent"  v-model="offer" :forAndId="'consent2'">
                    Я принимаю <AppHref @click="golink()">условия оформления заказа</AppHref> 
                </AppCheckbox>
          
                <router-link
                 @click.capture="handleClick"
                 :to="{ name: 'PageCartPayment', params: { shop_id: this.shop_id}}">
                    <AppButton class="cart-contact__button"
                    :styleButton="showSave">
                        Далее
                    </AppButton>
                </router-link>
    
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageCartComment',
     props:['shop_id'],
       data() {
        return {
         
            comment: "",
            personal: false,
            offer: false,
        }
    },
    mounted(){
        this.comment =  localStorage.getItem('comment') || ""
    },
     methods:{
        golink(){
            window.open("https://ivitrina.store/#info") 
        },
        handleClick() {
            localStorage.setItem('comment', this.comment)
             if (this.showSave!='_red active') event.stopPropagation();
            
        },
     },
     computed: {
        showSave(){
            let class_st = '_red _no-active';
             if (!this.personal) { return class_st;}
              if (!this.offer) { return class_st;}
            return '_red active';
           
        }
     },
}
</script>

<style lang="scss" scoped>
    .cart-comment__form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 18px;
    }
    .cart-comment__block-textarea {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
    }
    .cart-comment__block-textarea_title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .cart-comment__textarea {
        height: 72px;
    }
    .cart-contact__button {
        font-size: 16px;
        padding: 10px 0;
        margin: 0 auto;
        max-width: 172px;
    }
</style>