<template>
    <div class="title-small">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppTitleSmall'
}
</script>

<style lang="scss">
    .title-small {
        color: var(--gloabl_title_small_color);

        font-size: 11px;
    }
</style>