<template>
    <div  s>
        <br>
        <AppSectionTitle >
            Зона доставки
        </AppSectionTitle>
        <div class="cart-place__select-block" style="max-height: 250px; overflow-y: auto; ">
            <AppButton v-for="element in address" :key="element.id"
                class="option-contact__button" style="margin-top: 10px; padding: 5px; width: 98%; text-align: left;"
                :styleButton="(selectAddress==element.id)? '_active': '_red _no-active'"
                @click="setSelectAddress(element.id)"
            >
            <span style="width: 100%; margin-left: 3px;">{{element.name}}</span> <span> <strong>{{ (element.price==0)? "бесплатно" : element.price+" руб." }}</strong> </span>
            </AppButton>
            <!--
                <AppSelect
                    v-model="selectAddress"
                    :items="address"/>
            -->
        </div>
    </div>
</template>

<script>
 import api from "@/api"   
export default { 
    name: 'CartPlaceСourier', 
    props: ['shop_id',"shop_delivery_id"],
    data() {
        return {
            selectAddress: null,
            address: []
        };
    },
    mounted(){
        this.getPickups()
    },
    methods: {
        setSelectAddress(elementId){
            this.selectAddress = elementId
         
            this.$emit('updateСourierAddress', this.address.find(e=>e.id==this.selectAddress))
        },
        async getPickups(){
            const response =  await api.getPickups({id: this.shop_delivery_id,  shop_id: this.shop_id})
            this.address = response.data.data
            //this.selectAddress = this.address[0].id
        }
    },
    watch:{
        shop_delivery_id(){
            this.getPickups()
        },
     
        selectAddress(){
            this.$emit('updateСourierAddress', this.address.find(e=>e.id==this.selectAddress))
        }
    
    }
}
</script>

<style lang="scss" scoped>
    .cart-place__select-block span {
        display: inline-block;
    }
    .cart-place__select-block::-webkit-scrollbar {
    width: 8px;
    }

    .cart-place__select-block::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    }

    .cart-place__select-block::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    }
    .cart-place__small_title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .cart-place__select-block {
        border-bottom: 1px solid #E4E7ED;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
    .cart-place__add {
        margin-bottom: 16px;
    }
    .address-add {
        display: flex;
        flex-direction: column;
        row-gap: 21px;
        margin-bottom: 20px;
    }
    .address-add__cities-index {
        display: flex;
        column-gap: 18px;
    }
    .address-add__cities {
        flex: 1 1 auto;
    }
    .address-add__index {
        flex: 0 0 35%;
    }
    .address-email {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .address-add__street-house {
        display: flex;
        column-gap: 18px;
    }
    .address-add__street {
        flex: 1 1 auto;
    }
    .address-add__house {
        flex: 0 0 25%;
    }
    .address-add__bottom {
        display: flex;
        align-items: flex-end;
        column-gap: 16px;
        .address-add__button {
            margin: 0;
        }
    }
    .address-add__apartment {
        flex: 1 1 100%;
    }
    .cart-place__button {
        padding: 10px 0;
        margin: 0 auto;
        width: 172px;
    }
</style>