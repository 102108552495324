<template>
    <div class="title__setting-param">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppTitleSettingParam'
}
</script>

<style lang="scss">
    .title__setting-param {
        color: var(--global_second_color);

        font-size: 12px;
    }
</style>