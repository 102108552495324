/* global Ya */
import { createApp, watch } from 'vue';
import componentsUI from '@/components/UI';
import App from '@/App.vue';
import router from '@/router';
import VueLazyload from 'vue-lazyload';
import { loadScript } from 'vue-plugin-load-script';
import { getCounterIdByShopId } from '@/utils';

let appInstance;

async function loadYandexMetrikaScript() {
  return new Promise((resolve, reject) => {
    console.log('Loading Yandex Metrika script...');
    const script = document.createElement('script');
    script.src = 'https://mc.yandex.ru/metrika/watch.js';
    script.async = true;
    script.onload = () => {
      console.log('Yandex Metrika script loaded');
      resolve();
    };
    script.onerror = reject;
    document.head.appendChild(script);
  });
}
async function initYandexMetrika(counterId) {
  console.log('Initializing Yandex Metrika counter with ID:', counterId);
  await loadYandexMetrikaScript();

  (function (d, w, c) {
    (w[c] = w[c] || []).push(function () {
      try {
        w.yaCounter = new Ya.Metrika({
          id: counterId,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        });
        console.log('Yandex Metrika initialized with counter ID:', counterId);
      } catch (e) {
        // Do nothing if an error occurs during initialization
      }
    });
  })(document, window, 'yandex_metrika_callbacks');
}

loadScript("https://telegram.org/js/telegram-web-app.js").then(() => {
    appInstance = createApp(App);
    console.log(window.Telegram.WebApp)
    componentsUI.forEach(component => {
        appInstance.component(component.name, component);
    });

    appInstance
        .use(router)
        .use(VueLazyload)
        .mount('#app');

        watch(
          () => router.currentRoute.value.params.shop_id,
          async (newShopId, oldShopId) => {
            const counterId = getCounterIdByShopId(newShopId);
        
            if (!oldShopId) {
              await initYandexMetrika(counterId);
            } else {
              if (window.yaCounter) {
                window.yaCounter.setCounterId(counterId);
                console.log('Yandex Metrika counter ID updated to:', counterId);
              }
            }
        
            if (window.yaCounter) {
              window.yaCounter.hit(router.currentRoute.value.fullPath, {
                referer: router.currentRoute.value.fullPath,
              });
              console.log('Yandex Metrika hit sent for path:', router.currentRoute.value.fullPath);
            }
          },
          { immediate: true }
        );
})
.catch(() => {
    // Failed to fetch script
});