<template>
  <section class="section-cart-contact cart-contact">
    <div class="container">
      <AppCartNavigationProgress v-if="shop_id" :step="1" :shop_id="shop_id"></AppCartNavigationProgress>
      <AppSectionTitle class="cart-contact__title">
        Контактные данные
      </AppSectionTitle>
      <form class="cart-contact__form">
        <div class="cart-contact__inputs">
          <AppInputForm
              :pattern="patternName"
              :placeholder="'Ваше имя'"
              v-model="client.fio">
            <template v-slot:title>
              ФИО*
            </template>
          </AppInputForm>
          <AppInputForm
              class="add-first-number"
              :styleInput="{'padding-left': '25px'}"
              :typeInput="'tel'"
              :pattern="patternPhone"
              :placeholder="'0000000000'"
              v-model="client.phone">
            <template v-slot:title>
              Телефон*
            </template>
          </AppInputForm>
          <AppInputForm
              v-model="client.email"
              :pattern="patternEmail"
              :placeholder="'Ваш e-mail'"
              :typeInput="'email'">
            <template v-slot:title>
              E-mail
            </template>
          </AppInputForm>
        </div>
        <!--
        <AppCheckbox class="cart-contact__consent" :forAndId="'consent'"  v-model="personal">
            Я даю согласие на <AppHref>обработку моих персональных данных</AppHref>
        </AppCheckbox>
        <AppCheckbox class="cart-contact__consent"  v-model="offer" :forAndId="'consent2'">
            Я принимаю <AppHref>условия оформления заказа</AppHref>
        </AppCheckbox>
        -->
        <br>
      </form>
          <AppButton class="cart-contact__button"
                     :styleButton="showSave"
                     @click="handleClick"
          >
          Далее
          </AppButton>
        
          
      
    </div>
  </section>
</template>

<script>
function isTextInput(node) {
    return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
}
import api from "../../api"

export default {
  name: 'PageCartContact',
  props: ['shop_id'],
  data() {
    return {

      client: {},
      personal: false,
      offer: false,
      patternPhone: '^\\+?[0-9]{4,11}$',
      patternName: '^[a-zA-Zа-яА-Я _]+$',
      patternEmail: '^[^@\\n]+@\\w+\\.\\w+$',
    }
  },
  computed: {
    go() {
      return new RegExp(this.patternName).test(this.client.fio) &&
          new RegExp(this.patternPhone).test(this.client.phone) //&&
          //new RegExp(this.patternEmail).test(this.client.email);
    },
    showSave() {
      if (
          new RegExp(this.patternName).test(this.client.fio) &&
          new RegExp(this.patternPhone).test(this.client.phone) //&&
          //new RegExp(this.patternEmail).test(this.client.email)
      ) return '_red active';
      return '_red _no-active';
    }
  }, 
  mounted() {
    let initData = window.Telegram.WebApp.initData
    if (!initData) initData = 'query_id=AAH3MwRmAAAAAPczBGag4wv7&user=%7B%22id%22%3A1711551479%2C%22first_name%22%3A%22G%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22G922816%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1658815162&hash=1f3213380c7e4d4024ca44258bc3812f014e9eab182dae613eb20b17e4186acf'
    this.getInfo(initData)
    document.addEventListener('touchstart', function(e) {
            if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
                console.log("blur");
                document.activeElement.blur();
            }
        }, false);
  },
  methods: {
    handleClick() {
      if (!this.go) return;
      this.client.shop_id = this.shop_id
      localStorage.setItem('client', JSON.stringify(this.client))
      api.createUpdateClient(this.client).then(
        this.$router.push({ name: 'PageCartPlaceOption', params: { shop_id: this.shop_id}})
      )
    },
    async getInfo(initData) {
      const response = await api.getClient({initData: initData, shop_id: this.shop_id})
      this.client = response.data.data
    } 
  },
}
</script>

<style lang="scss" scoped>
.cart-contact__title {
  font-size: 14px;
  margin-bottom: 20px;
}

.cart-contact__inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 12px;
  @media (max-width: 400px) {
    // grid-template-columns: 1fr;
    grid-gap: 10px;
    & {
      *:first-child {
        grid-column: 1/3;
      }
    }
  }
}

.cart-contact__consent {
  margin-top: 15px;
  margin-bottom: 17px;
}

.cart-contact__button {
  font-size: 16px;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 172px;
}

.add-first-number {
  position: relative;
}

.add-first-number:before {
  //content: "+7";
  position: absolute;
  left: 11px;
  top: 32px;
  color: black;
  z-index: 1;
  font-size: 12px;
  font-family: Roboto;
}

.cart-contact__text-message {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  grid-column: 1/3;
  @media (max-width: 400px) {
    grid-column: 1;
  }
}

.cart-contact__text-message_title {
  font-size: 12px;
  color: #7E7E7E;
}

.cart-contact__text-message_textarea {
  padding: 11px;
  min-height: 82px;
}
</style>