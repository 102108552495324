<template>
    <div>
        <br>
        <AppSectionTitle >
            Адрес cамовывоза
        </AppSectionTitle>
        <div class="cart-place__select-block">
            <AppSelect
                v-model="selectAddress"
                :items="address"/>
        </div>
    </div>
</template>

<script>
 import api from "@/api"   
export default {
    name: 'CartPlacePickup',
    props: ['shop_id',"shop_delivery_id"],
    data() {
        return {
            selectAddress: null,
            address: []
        };
    },
    mounted(){
        this.getPickups()
    },
    methods: {
        async getPickups(){
            const response =  await api.getPickups({id: this.shop_delivery_id,  shop_id: this.shop_id})
            this.address = response.data.data
            this.selectAddress = this.address[0].id
        }
    },
    watch:{
        shop_delivery_id(){
            this.getPickups()
        },
        selectAddress(){
            this.$emit('updateAddress', this.address.find(e=>e.id==this.selectAddress))
        }
    }
}
</script>

<style lang="scss" scoped>
    .cart-place__small_title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .cart-place__select-block {
        border-bottom: 1px solid #E4E7ED;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
    .cart-place__add {
        margin-bottom: 16px;
    }
    .address-add {
        display: flex;
        flex-direction: column;
        row-gap: 21px;
        margin-bottom: 20px;
    }
    .address-add__cities-index {
        display: flex;
        column-gap: 18px;
    }
    .address-add__cities {
        flex: 1 1 auto;
    }
    .address-add__index {
        flex: 0 0 35%;
    }
    .address-email {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .address-add__street-house {
        display: flex;
        column-gap: 18px;
    }
    .address-add__street {
        flex: 1 1 auto;
    }
    .address-add__house {
        flex: 0 0 25%;
    }
    .address-add__bottom {
        display: flex;
        align-items: flex-end;
        column-gap: 16px;
        .address-add__button {
            margin: 0;
        }
    }
    .address-add__apartment {
        flex: 1 1 100%;
    }
    .cart-place__button {
        padding: 10px 0;
        margin: 0 auto;
        width: 172px;
    }
</style>