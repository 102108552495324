<template>
  <div class="slider">
    <div class="slider__arrow">
      <AppArrow
          :class="[leftArrowLocked ? '_no-active' : '']"
          v-if="slides[1]"
          @click="clickerLeftArrow()"/>
    </div>
    <div class="slider-container">
      <ul class="slider__content"
          :style="{transform: 'translateX('+ translateXPosition + '%)'}">
        <li
            class="slider__content_item"
            v-for="slide in slides"
            :key="slide.id">
          
          <div class="slider__content_item_inner">
            <div
                class="slider__content_video"
                v-if="slide.type === 'video'">
              <video  class="slider__content_video-mp4" controls>
                <source
                    :src="require(`@/assets/${slide.path}`)">
              </video>
            </div>
            
            <AppImageLink
                class="slider__content_img"
                v-else
                :image="slide[format]"
                :link="link"/>
            
          </div>
        </li>
      </ul>
    </div>
    <div class="slider__arrow">
      <AppArrow
          :class="[RightArrowLocked ? '_no-active' : '']"
          v-if="slides[1]"
          @click="clickerRightArrow()"></AppArrow>
    </div> 
  
    <AppViewer
            v-if="showViewer"
            @closeViewer="showViewer = false"
            :img="currentImgUrl"
        />
  </div>
</template>

<script>
export default {
  name: 'AppSliderAbout',
  data() {
    return {
      translateXPosition: 0,
      leftArrowLocked: true,
      RightArrowLocked: false,
      currectImageIndex: 0,
      currentImgUrl:"",
      showViewer: false,
    }
  },
  props: {
    format:{
      type: String,
      default: function () {  return 'webp' }
    },
    slides: {
      type: Array,
      required: true
    }
  },
  methods: {
    setCurrentImgUrl(event) {
      if (event.target && event.target.src) {
        this.currentImgUrl = this.slides[this.currectImageIndex].thumb;
  
        this.showViewer = true;
      }
    },
    addSwipe() {
      let setCurrentImgUrl = this.setCurrentImgUrl
      let start, end, ctx = this;
      let slide = document.querySelector('.slider-container');
      slide.addEventListener('mousedown', function (event) {
        //console.log("event.clientX");
        start = event.clientX;
        slide.addEventListener('mouseup', function (event) {
          //console.log(event.clientX);
          end = event.clientX;
          let diff = start - end;
          if (Math.abs(diff)<20) {
            setCurrentImgUrl(event)
            //ctx.$emit('showImg', event);
          } else {
            diff>0?ctx.clickerRightArrow():ctx.clickerLeftArrow();
          }
        }, {once: true})
      })
    },
    addSwipeMob() {
      let start, end, ctx = this;
      let slide = document.querySelector('.slider-container');
      slide.addEventListener('touchstart', function (event) {
        let clientX = event.touches[0].clientX;
        //console.log(clientX);
        start = clientX;
        slide.addEventListener('touchend', function (event) {
          let clientX = event.changedTouches[0].clientX;
          //console.log(clientX);
          end = clientX;
          let diff = start - end;
          if (Math.abs(diff)<20) {
            ctx.$emit('showImg', event);
          } else {
            diff>0?ctx.clickerRightArrow():ctx.clickerLeftArrow();
          }
        }, {once: true})
      })
    },
    clickerLeftArrow() {
      if (this.translateXPosition >= 0) {
        this.leftArrowLocked = true;
        return;
      }
      this.RightArrowLocked = false;
      this.currectImageIndex-- 
      return this.translateXPosition += 100;
    },
    clickerRightArrow() {
      if (this.translateXPosition <= (-100 * (this.slides.length - 1))) {
        this.RightArrowLocked = true;
        return;
      }
      this.leftArrowLocked = false;
      this.currectImageIndex++
      return this.translateXPosition -= 100;
    }
  },
  mounted() {
    //console.log(this.slides,"slides")
    this.addSwipe();
    this.addSwipeMob();
  }
}
</script>

<style lang="scss" scoped>
.slider {
  display: flex;
  align-items: center;
  position: relative;
}
.slider-container {
  width: 100%;
  overflow: hidden;
}
.slider__arrow {
  position: absolute;
  &:first-child {
    left: -18px;
    @media (max-width: 400px) {
      left: -15px;
    }
  }
  &:last-child {
    right: -18px;
    transform: rotate(180deg);
    @media (max-width: 400px) {
      right: -15px;
    }
  }
}
.slider__content {
  display: flex;
  position: relative;
  transition: 300ms;
  width: 100%;
}
.slider__content_item {
  display: flex;
  justify-content: center;
  flex: 1 0 100%;
  padding: 0 5px 0 5px;
}
.slider__content_item_inner {
  width: 50%;
  cursor: pointer;
}
.slider__content_video {
  margin-left: -60px;
  position: relative;
  padding-top: 100%;
  .slider__content_video-mp4 {
    
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.slider__content_img {
  @extend .slider__content_video;
}
</style>