<template>
    <section class="section-feedback feedback">
        <div class="container">
            <form class="feedback__form" action="" onsubmit="return false;">
                <div class="feedback__inputs">
                    <AppInputForm
                     v-model="client.fio">
                        <template v-slot:title>
                            ФИО
                        </template>
                    </AppInputForm> 
                    <AppInputForm
                    v-model="client.phone"
                    :typeInput="'tel'"
                            >
                        <template v-slot:title>
                            Телефон*
                        </template>
                    </AppInputForm>
                    <AppInputForm
                    v-model="client.email"
                    :typeInput="'email'">
                        <template v-slot:title>
                            E-mail
                        </template>
                    </AppInputForm>
                    <div class="feedback__text-message">
                        <div class="feedback__text-message_title">
                            Текст сообщения 
                        </div>
                        <AppTextareaForm class="feedback__text-message_textarea"  
                            v-model="client.text"
                            placeholder="Не менее 10 символов"
                            >
                        </AppTextareaForm>
                    </div>
                </div>
                <AppCheckbox class="feedback__consent" :forAndId="'consent'"  v-model="personal">
                    Я даю согласие на <AppHref  @click="golink()">обработку моих персональных данных</AppHref>
                </AppCheckbox>
                <AppButton class="feedback__button"
                @click="handleClick()"
                :styleButton="showSave">
                    Отправить
                </AppButton>
            </form>
        </div>
      
    </section>
</template>

<script>
function isTextInput(node) {
    return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
}
import api from "../api"
export default {
    name: "PageFeedback",
    props: ['shop_id'],
    data() {
        return {
            client: {},
            personal: false,
          showError: false,
        }
    },
    computed: {
        go(){
            if (!this.client.fio) { return false;}
            if (!this.client.text) {
                return false;
            }
            if (this.client.text.length<10) { return false;}
            //console.log("name")
            if (this.client.phone<7) {return false;}
          //   console.log("price")
           // if (!this.client.email) { return false;}
            if (!this.personal) { return false;}
            //  if (!this.offer) { return false;}
    
            return true;
        },
        showSave(){
            let class_st = '_red _no-active';
         
            if (this.client.text && this.client.text.length<10) { 
                return class_st;
                }
            if (!this.personal) { return class_st;}
            if (!this.client.text)  return class_st;
           
           //   if (!this.offer) { return class_st;}
            
            
            if (!this.client.fio) { return class_st;}
            //console.log("name")
            if (this.client.phone<7) {return class_st;}
          //   console.log("price")
           // if (!this.client.email) { return class_st;}

            return '_red active';
           
        }
     },
    
    mounted(){
        
       let initData = window.Telegram.WebApp.initData
       if (!initData) initData = 'query_id=AAH3MwRmAAAAAPczBGag4wv7&user=%7B%22id%22%3A1711551479%2C%22first_name%22%3A%22G%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22G922816%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1658815162&hash=1f3213380c7e4d4024ca44258bc3812f014e9eab182dae613eb20b17e4186acf'
       this.getInfo(initData)
       document.addEventListener('touchstart', function(e) {
            if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
                console.log("blur");
                document.activeElement.blur();
            }
        }, false);
    },
    methods: {
        golink(){
            window.open("https://ivitrina.store/#info") 
        },
        handleClick() {
             if (!this.go) return;
            this.client.shop_id = this.shop_id
            localStorage.setItem('client', JSON.stringify(this.client))
            api.sendFeedback(this.client).then()
            window.Telegram.WebApp.close()
        },
         async getInfo(initData){
            const response =  await api.getClient({initData: initData,  shop_id: this.shop_id})
            this.client =  response.data.data
     
        }
    }
}
</script>

<style lang="scss" scoped>
    .feedback__form {
        padding-bottom: 124px;
        margin-top: 25px;
    }
    .feedback__title {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .feedback__inputs { 
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 23px 17px;
        @media (max-width: 400px) {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }
    .feedback__consent {
        margin-top: 15px;
        margin-bottom: 17px;
    }
    .feedback__button {
        font-size: 16px;
        padding: 10px 0;
        margin: 0 auto;
        max-width: 172px;
    }
    .feedback__text-message {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
        grid-column: 1/3;
        @media (max-width: 400px) {
            grid-column: 1;
        }
    }
    .feedback__text-message_title {
        font-size: 12px;
        color: var(--global_fourth_color);
    }
    .feedback__text-message_textarea {
        padding: 11px;
        min-height: 82px;
    }
</style>