// src/utils.js
export function getCounterIdByShopId(shop_id) {
    // Replace the following object with the actual mapping of shop_ids to counter IDs
    const shopToCounterMap = {
        '10': '92634431',
        '1': '92900147',
        '35': '92901419',
        '180': '94187118',
    };
    const counterId =shopToCounterMap[shop_id] || 'default_counter_id';
    console.log('Getting counter ID for shop ID:', shop_id, 'Counter ID:', counterId);
    return counterId
  }

