<template>
  <div class="button-bay">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "AppBasketBottomBanner"
}
</script>

<style scoped>
.button-bay {
  width: 80%;
  height: 40px;
  background: #317093;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  margin: 40px auto -20px auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

</style>