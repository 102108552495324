<template>
    <div class="orders-table orders__container">
        <div class="orders-table__content">
            <div class="orders-table__names">
                <AppTitleSmall class="orders-table__name">
                    №
                </AppTitleSmall>
                <AppTitleSmall class="orders-table__name">
                    Статус
                </AppTitleSmall>
                <AppTitleSmall class="orders-table__name">
                    Платеж:
                </AppTitleSmall>
            </div>
            <ul class="orders">
                <li class="order"
                v-for="order in orders"
                :key="order.id">
                    <div class="order__short-info">
                        <div class="order__id">
                            {{ order.id }}
                        </div>
                        <div class="order__status">
                            {{ order.status }}
                        </div>
                        <div class="order__payment">
                            {{ order.payment }}
                        </div>
                    </div>
                    <div class="order__interaction">
                        <div class="order__interaction_text">
                            <AppTitleSmall class="order__interaction_title-small">
                                Сумма заказа:
                            </AppTitleSmall>
                            <AppProductPrice class="order__interaction_text-span order__price">
                                {{ order.price }}
                            </AppProductPrice>
                        </div>
                        <AppButton class="order__interaction_button"
                        :styleButton="'_blue-external'">Подробнее</AppButton>
                    </div>
                </li>
            </ul>
        </div>
        <AppNavigationBottom class="orders-table__buttons"></AppNavigationBottom>
    </div>
</template>

<script>
export default {
    name: "AppOrders",
    props: {
        orders: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .orders-table__content {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        padding: 11px 13px;
        margin-bottom: 14px;
    }

    .orders-table__names {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        position: relative;
        margin-bottom: 10px;

        &::after {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            bottom: -10px;

            height: 1px;
            width: 100%;
        }
    }

    .orders {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .order {
        color: rgba(0, 0, 0, 0.75);

        position: relative;

        padding-top: 8px;

        &::before {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            top: 0;

            height: 2px;
            width: 100%;
        }
    }

    .order__short-info {
        display: grid;
        align-items: start;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        font-size: 12px;

        .order__price {
            font-size: 12px;

            text-align: right;
        }
    }

    .order__id {
        color: var(--default_background);

        font-size: 14px;
        font-weight: 500;
    }

    .order__title {
        color: rgba(0, 0, 0, 0.75);

        font-size: 14px;
    }

    .order__additional-info_span {
        font-size: 12px;
    }

    .order__interaction_text-span  {
        font-size: 12px;
    }

    .order__interaction {
        border-top: 1px solid #E4E7ED;

        display: flex;
        justify-content: space-between;

        margin-top: 9px;
        padding-top: 9px;
    }

    .order__interaction_text {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    .order__interaction_button {
        width: 80px;
        height: 30px;
    }
</style>