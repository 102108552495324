<template>
    <ul class="images__list">
        <li 
        class="images__item"
        v-for="image in images"
        :key="image.id">
            <AppImage
            :name="image.name"
            :showDelete="showDelete"></AppImage>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AppImageItems',
    props: {
        images: {
            type: Array,
            required: true
        },
        showDelete: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .images__list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
    }

    .images__item {
        position: relative;

        width: 36px;
        height: 36px;
    }
</style>