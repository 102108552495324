<template>
    <section class="section-catalog">
        <div class="container">
            <AppSectionTitle>
                Категории товаров
            </AppSectionTitle>
            <ul class="catalog__menu">
                <li class="catalog__menu_item"
                v-for="catalogItem in catalogItems"
                :key="catalogItem.name">
                    <router-link v-if="catalogItem.id" :to="getLink(catalogItem)">
                        <AppButtonExternal class="catalog__button">
                            {{ catalogItem.name }}
                        </AppButtonExternal>
                    </router-link>
                </li>
            </ul> 
            <AppMoneyRu v-if="shop_id" :shop_id="shop_id"/>
        </div>
    </section>
</template>

<script>
import api from "../../api"
export default {
    name: "PageCatalog",
    props: ['shop_id'],
    data() { 
        return {
            catalogItems: []
        }
    },
     mounted() {
      
        this.getCategories()
    },
    methods: {
        getLink(category){
            return {
                    name: "PageCatalogProducts", 
                    params: {
                        category_id: category.id,
                        shop_id:this.shop_id, 
                        category_name: category.name, 
                        }
                    } 
        },
 
        async getCategories() {
            this.catalogItems = []
            const response =  await api.getCategories(this.shop_id)
            let data =  response.data.data
            
            for (let index=0; index<data.length; index++) {
                if (data[index].name=="Основная") continue; 
                this.catalogItems.push(data[index])
                }
        },
    }
}
</script>

<style lang="scss">
    .catalog__menu {
        display: flex;
        flex-direction: column;
        column-gap: 6px;
      .catalog__menu_item {
        margin-bottom: 6px;
      }
    }

    .catalog__button {
        padding: 12px 0;
        margin: 4px;
    }

</style>