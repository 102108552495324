<template>
    <div class="input-content">
        <label class="input__title" for="">
            <slot name="title"></slot>
        </label>
        <div class="input__content">
            <input class="input"
            :type="typeInput"
            :value="modelValue"
            @input="updateInput">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppInput',
    props: {
        typeInput: {
            type: String,
            default: 'text'
        },
        modelValue: { 
            type: [String, Number]
        }
    },
   methods: {
        updateInput(event) {
            let input = event.target.value
         
             switch(this.typeInput) {
                case "float":
                    event.target.value = this.checkFloat(input)
                    ///console.log(input,'input')
                    break;
                case "integer":
                    event.target.value = this.checkItenger(input)
                    break;
                default:
                    break;
             }
            this.$emit('update:modelValue', event.target.value);
        },
        checkItenger(input){
            let ch = input.replace(/[^\d]/g, ''); //разрешаем вводить только числа
            return ch
        },
        checkFloat(input){
            let ch = input.replace(/[^\d,]/g, ''); //разрешаем вводить только числа и запятую
            let pos = ch.indexOf(','); // проверяем, есть ли в строке запятая
            if(pos != -1){ // если запятая есть
                if((ch.length-pos)>3){ // проверяем, сколько знаков после запятой, если больше 1го то
                    ch = ch.slice(0, -1); // удаляем лишнее
                }
            }
            return ch
        }
       
    }
}
</script>

<style lang="scss" scoped>
    .input-content {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .input__title {
        color: #7E7E7E;

        font-size: 12px;
    }

    .input {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 6px;

        padding: 11px;
        padding-right: 28px;

        width: 100%;
        height: 40px;

        &[type='email'] {
            text-decoration: underline;
        }
    }
</style>