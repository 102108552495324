<template>
    <AppButton
    class="catalog-setting__add"
    :styleButton="'_blue-external'">
        <AppButton
        class="catalog-setting__add_button"
        :styleButton="'_blue-external'">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3137 6.72882V9.35523H0.313721L0.313721 6.72882L16.3137 6.72882ZM9.8047 0.313721V16.3137H6.83877L6.83877 0.313721L9.8047 0.313721Z" fill="#20A0FF"/>
            </svg>
        </AppButton>
        <div class="catalog-setting__add_text">
            <slot></slot>
        </div>
    </AppButton>
</template>

<script>
export default {
    name: 'AppButtonAdd'
}
</script>

<style lang="scss">
    .catalog-setting__add {
        outline: 0 !important;

        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: left;

        svg {
            margin-left: 1px;
        }
    }

    .catalog-setting__add_button {
        width: 36px;
        height: 36px;
    }

    .catalog-setting__add_text {
       font-size: 14px;
    }
</style>