<template>
    <div class="setting__navigation">
        <AppSectionTitle>
            Настройки
        </AppSectionTitle>
        <nav class="setting__nav">
            <router-link class="setting__link" to="/setting">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 9) !== '' ? '_grey' : null">
                    Настройки
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/showcase">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 17) !== '/showcase' ? '_grey' : null">
                    Настрока витрины
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/catalog">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 16) !== '/catalog' ? '_grey': null">
                    Настройка каталога
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/bot">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 12) !== '/bot' ? '_grey': null">
                    Настройка бота
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/delivery">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 17) !== '/delivery' ? '_grey': null">
                    Доставка
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/stock">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 14) !== '/stock' ? '_grey': null">
                    Настройка акций
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/alert">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 14) !== '/alert' ? '_grey': null">
                    Уведомления
                </AppButton>
            </router-link>
            <router-link class="setting__link" to="/setting/about">
                <AppButton
                class="setting__button"
                :styleButton="$router.currentRoute.value.path.substring(8, 14) !== '/about' ? '_grey': null">
                    О компании
                </AppButton>
            </router-link>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'AppSettingNavigation'
}
</script>

<style lang="scss" scoped>
    .setting__nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px 14px;
    }

    .setting__button {
        height: 30px;
    }
</style>