<template>
      <div class="modal-message"
          v-if="state"
          >
        <svg  class="modal-message-close"
              @click="close"
              width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="19.7988" width="28" height="4" transform="rotate(-45 0 19.7988)" fill="black"/>
          <rect width="28" height="4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.6274 19.7988)" fill="black"/>
        </svg>
        <div class="content">
          <div class="message" v-html="message">
       
          </div>
          <AppButton v-if="showButton"
            @click="push()"
              class="error-button"
              :styleButton="'_red'"
          >
            {{ buttonName }}
          </AppButton>
        </div>
      </div>
</template>

<script>
export default {
  name: "AppModalMessage",
  components: {},

  data() {
    return {

    }
  },
  props: {
    state: {
      type: Boolean,
      default() {
        return false
      }
    },
    message: {
      type: String,
      default() {
        return'При оформлении произошла ошибка'
      }
    },
    showButton: {
      type: Boolean,
      default() {
        return true
      }
    },
    buttonName: {
      type: String,
      default() {
        return'вернуться в корзину'
      }
    },
    buttonLink: {
      type: String,
      default() {
        return 'PageCart'
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    push() {
      this.$emit('push')
    },
  }
}
</script>

<style scoped>
.modal-message {
  width: 300px;
  min-height: 100px;
  max-height: 270px;
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:5;
}

.content {
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}

.message {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 70px auto 20px auto;
  width: 220px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.error-button {
  width: 170px;
  height: 36px;
  margin: auto;
}

.modal-message-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  min-width: 100vw;
  height: 100vh;
  background: rgba(70, 78, 96, 0.75);
}

</style>