<template>
    <button
    class="button"
    :class="styleButton">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'AppButtonExternal',
    props: {
        styleButton: {
            type: String,
            required: false
        }
    }
}
</script>

<style lang="scss">
    .button {
        background: var(--default_background);
        border-radius: 6px;
        color: var(--default_color);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 200ms;
        width: 100%;
        svg > * {
            transition: 200ms;
            fill: var(--default_color);
        }
        &:hover {
            background: var(--default_background_hover);
        }
        &:active {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #20A0FF;
        }
        &._no-active {
             background: var(--default_background_active);
        }
        &._active {
            background: var(--default_background_active_confirm);
        }
        &._grey {
            background: none;
            color: #8EA2B0;
            outline: 1px solid #8EA2B0;
            svg > * {
                fill: #8EA2B0;
            }
            &:hover {
                color: var(--default_background);
                outline: 1px solid var(--default_background);;
                svg > * {
                    fill: var(--default_background);
                }
            }
            &:active {
                color: #798B97;
                outline: 1px solid #798B97;
                svg > * {
                    fill: #798B97 ;
                }
            }
            &._no-active {
                color: #B2BCC3;
                outline: 1px solid #B2BCC3;
                svg > * {
                    fill: #B2BCC3 ;
                }
            }
            &._active {
                background: #B2BCC3;
                color: #FFFFFF;
                outline: 1px solid #B2BCC3;
                svg > * {
                    fill: #FFFFFF ;
                }
            }
        }
        &._blue-external {
            background: none;
            color: var(--default_color);
            outline: 1px solid var(--default_background);
            svg > * {
                fill: var(--default_background);
            }
            &:hover {
                color: var(--default_color);
                outline: 1px solid var(--default_background_hover);
                svg > * {
                    fill: var(--default_background_hover) ;
                }
            }
            &:active {
                color: var(--default_color);
                outline: 1px solid var(--default_background_active);
                svg > * {
                    fill: var(--default_background_active) ;
                }
            }
            &._no-active {
                color: #B2BCC3;
                outline: 1px solid #B2BCC3;
                svg > * {
                    fill: #B2BCC3 ;
                }
            }
            &._active {
                background: #1188E0;
                color: #FFFFFF;
                outline: 1px solid #1188E0;
                svg > * {
                    fill: #FFFFFF ;
                }
            }
        }
        &._red {
            background: #FF0000;
            border-radius: 6px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 200ms;
            width: 100%;
            &:hover {
                background: #FF4444;
            }
            &:active {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FF0000;;
            }
            &._no-active {
                background: #B2BCC4;
            }
            &._active {
                background: #B90000; 
            }
        }
        &._red-external {
            background: none;
            color: #FF0000;
            outline: 1px solid #FF0000;
            svg > * {
                fill: #FF0000;
            }
            &:hover {
                color: #FF4444;
                outline: 1px solid #FF4444;
                svg > * {
                    fill: #FF4444 ;
                }
            }
            &:active {
                color: #B90000;
                outline: 1px solid #B90000;
                svg > * {
                    fill: #B90000 ;
                }
            }
            &._no-active {
                color: #B2BCC3;
                outline: 1px solid #B2BCC3;
                svg > * {
                    fill: #B2BCC3 ;
                }
            }
        }
        &._white {
            background: #FFFFFF;
            border: 1px solid #20A0FF;
            color: #20A0FF;
            svg > * {
                fill: #20A0FF ;
            }
            &:hover {
                background: rgba(26, 128, 204, 0.5);
                border: 1px solid #FFFFFF;
                color: #FFFFFF;
                svg > * {
                    fill: #FFFFFF ;
                }
            }
            &:active,
            &._active {
                background: #1A80CC;
                border: 1px solid #FFFFFF;
                color: #FFFFFF;
                svg > * {
                    fill: #FFFFFF ;
                }
            }
            &._no-active {
                background: #B2BCC3;
                color: rgba(255, 255, 255, 0.75);
                svg > * {
                    fill: rgba(255, 255, 255, 0.75) ;
                }
            }
        }
        &._white-external {
            background: none;
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            &:hover {
                background: rgba(255, 255, 255, 0.15);
            }
            &:active {
                background: rgba(255, 255, 255, 0.3);
            }
            &._no-active {
                border: 1px solid #B2BCC3;
                color: #B2BCC3;
            }
        }
        
    }
    _global-dark {
        .button {
            &._blue-external,
            &._grey {
                color: var(--default_color);
            }
        }
    }
</style>