<template>
    <div class="section-catalog">
        <div class="container">
            <div class="catalog__top">
                <AppSectionTitle>
                      {{stock_name}}
                </AppSectionTitle>
                <!--
                <AppFilter
                :filterContent="filterContent"></AppFilter>
                -->
            </div>
            <AppProducts
            v-if="shop_id"
            :shop_id="shop_id"
            :stock_id="stock_id"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageStockProducts',
    props: ['shop_id', 'stock_id', 'stock_name'],
    data() {
        return {
            products: []
        }
    },
    mounted() {
       
    },
    methods: {
    }  
}
</script>

<style lang="scss" scoped>
    .section-catalog {
        padding-bottom: 17px;
    }

    .catalog__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>