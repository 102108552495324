<template>
  <div class="container products__container">
    <ul class="products" v-if="products[0] && products[0].type_id!=10">
      <li class="product block-style"
          v-for="product in products"
          :key="product.id"
          v-lazy-container="{ selector: 'img' }">
        <router-link v-if="product!=undefined" class="product__title" :to="getLink(product.id)">
          <AppImageLink v-if="product.images.length>0"
                    class="product__image"
                    :stocks="product.stocks"
                    :image="product.images[0].webp"
          />
      
          <div :id="'product__name_' + product.id" class="product__name">{{ getProductName(product) }}</div>
        </router-link>
        <div class="product__description" v-if="product">
          <div>
            <span v-if="product.description" class="description_text" v-html="getDescription(product.description)">

            </span>
          </div>
        </div>
        <AppProductPrice class="product__price" v-if="[1,3,4].includes(product.type_id)">
          {{ Number(product.price).toLocaleString() }}
        </AppProductPrice>
        <div v-if="[2].includes(product.type_id)">
          <br>
        </div>

        <div class="product__interaction" v-if="[1,3,4].includes(product.type_id)">
          <AppInputCounter v-if="product.type_id!=3" 
            @update="updateCounter" 
            :productId="product.id"
            :quantity="product.quantity"
            />
          <AppProductButton @update="addToCart" :productId="product.id"/>
        </div>

        <div class="product__interaction" v-if="[2].includes(product.type_id)">
          <br>
          <router-link v-if="product!=undefined" class="product__title" :to="getLinkState(product.id)">
            <AppProductStateButton/>
          </router-link>
        </div> 
      </li>
    </ul>
    <ul class="products" v-if="products[0] && products[0].type_id==10">
      <li class="product block-style"
          v-for="product in products"
          :key="product.id"
          v-lazy-container="{ selector: 'img' }">
        <router-link v-if="product!=undefined" class="product__title" :to="getLink(product.id)">
          <AppImageIiko v-if="product.images.length>0"
                    class="product__image"
                    :image="product.images[0]"
          />
          <div :id="'product__name_' + product.id" class="product__name">{{ getProductName(product) }}</div>
        </router-link>
        <div class="product__description" v-if="product">
          <div>
            <span v-if="product.description" class="description_text" v-html="getDescription(product.description)"/>
          </div>
        </div>
        <AppProductPrice class="product__price">
          {{ Number(product.price).toLocaleString() }}
        </AppProductPrice>
    
        <div class="product__interaction">
          <AppInputCounter  v-if="!product.modifiers"
            @update="updateCounter" 
            :productId="product.id"
            :quantity="product.quantity"
            />
          <AppProductButton v-if="product.modifiers" style="cursor: pointer;" @update="addToCartIiko" :productId="product"/>
          <AppProductButton v-if="!product.modifiers" style="cursor: pointer;" @update="updateIikoCart" :productId="product"/>
        </div>
      </li>
    </ul>
      <AppMoneyRu v-if="shop_id"
        :shop_id="shop_id"
        :update="getCart"
        :addProduct="addProduct"
        :upCartSwith="upCartSwith"
      /> 
      <AppModifiers 
        v-if="shop_id && show_modifiers"
        @close="show_modifiers=false"
        :product="addProduct"
        @updateCart="updateIikoCart"
        />


    <!--
    <AppTelegramButton v-if="shop_id"
                      
          />
    -->
    <!-- <AppButton class="product__show-more"
                v-if="!hidden"
                :styleButton="'_grey'">
       <div class="product__show-more_text" @click="addProducts">
         Показать еще
       </div>
       <svg class="product__show-more_svg" width="12" height="8" viewBox="0 0 12 8" fill="none"
            xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd"
               d="M5.87298 5.22424L10.6812 0.416016L11.9383 1.67309L6.28143 7.32995L6.21612 7.26464L5.80767 7.67309L0.150818 2.01624L1.4079 0.759161L5.87298 5.22424Z"
               fill="#8EA2B0"/>
       </svg>
     </AppButton>
   -->
  </div>
</template>

<script>
import api from "../../api";
import textFit from "textfit";

function addSlowscroll(el, callback) {
  el.addEventListener(
      'scroll',
      function () {
        setTimeout(function () {
          callback();
          addSlowscroll(el, callback);
        }, 200);
      },
      {once: true}
  );
}

export default {

  name: 'AppProducts',
  props: ['shop_id', 'category_id', 'stock_id', 'sortItem'],
  data() {
    return {
      show_modifiers: false,
      upCartSwith: false,
      addProduct: {},
      hidden: true,
      products: [],
      correctPage:0,
      page: 1,
      pageSize: 6,
      lastPage: 1,
      cart: {
        products: []
      },
    }
  },
  components: {},
  methods: {
      //Добавление в корзину iiko
    addToCartIiko(product) {
      this.show_modifiers = false;
      this.addProduct = this.products.find(item => item.id == product.id)
      if (product.modifiers) {
        this.show_modifiers = true;
      }
      return 
    },
    updateIikoCart(element){
      this.addProduct = element
      this.upCartSwith = !this.upCartSwith;
        this.show_modifiers=false
    },  
    async loadProducts() {
      this.correctPage = this.page
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        shop_id: this.shop_id,
        sortBy: (this.sortItem) ? this.sortItem : "id,desc",
      }
      if (this.category_id != undefined) data.category_id = this.category_id
      if (this.stock_id != undefined) data.stock_id = this.stock_id
      let res = await api.getProducts(data) 
      let f = res.data.data

      for (let index = 0; index < f.length; index++)
        f[index].counter = 1
      this.page = res.data.meta.currentPage + 1
      this.pageSize = res.data.meta.pageSize
      this.lastPage = res.data.meta.pages

      if (res && res.status === 200){
        this.products = this.products.concat(res.data.data);
        this.getCart();
      }
        
    },

    async checkEndList() {

      let scrollContainer = document.querySelector('.main');
      let scrollBottom =
          scrollContainer.scrollHeight -
          scrollContainer.clientHeight -
          scrollContainer.scrollTop;
      if (scrollBottom < 500 && this.page <= this.lastPage && this.page > 1 && this.page > this.correctPage)
         this.loadProducts();
    },

    getProductName(product) {
      setTimeout(function () {
        let box = document.querySelector('#product__name_' + product.id);
        this.textFit(box, {minFontSize: 8, maxFontSize: 16, multiLine: true});
      }.bind(this));
      return product.name;
    },

    getDescription(text){
      //text = text.replace(/<(?:.|\n)*?>/gm, '')
      return text.substr(0, 150)+'...';
     
    },
    getCart() {
      setTimeout( function () {
        this.cart = JSON.parse(localStorage.getItem('cart'+this.shop_id)) ||  {products:[], info:{}};
        for (let index = 0; index < this.cart.products.length; index++) {
        let product = this.cart.products[index]
        let inf = this.products.find(function (e) { return e.id == product.id; });
   
        if (inf) {
          if (product.counter>=inf.quantity) inf.blockCard = true
          else inf.blockCard = false
        }
      }
     // console.log(this.cart)
      }.bind(this),100)
      
    },

    reCalcFSizeOnResize() {
      let products = Array.from(document.querySelectorAll('.product__name'));
      if (products && products.length) {
        products.forEach(function (p) {
          this.textFit(p, {minFontSize: 8, maxFontSize: 16, multiLine: true});
        }.bind(this))
      } 
    },
    blockCart(product){
        if (product.type_id==2)  return false
        let inf = this.cart.products.find(function (e) { return e.id == product.id; });

        if (inf) 
          if (inf.counter>=inf.quantity) return false
        return true
       


       
    },
    addListeners() {
      let scrollContainer = document.querySelector('.main');
      addSlowscroll(scrollContainer, this.checkEndList);
    },
    addProducts() {
      this.page++
      this.getProducts()
    },

    //Обновление кол-ва
    updateCounter(counter, productId) {
     
      let index = this.products.findIndex(item => item.id == productId)
     // if (this.products[index].blockCard) return
      this.products[index].counter = counter;
      //this.getCart()
    },
  
    //Добавление в корзину
    addToCart(productId) {
      this.addProduct = this.products.find(item => item.id == productId)
      if (this.addProduct.blockCard) return
      this.upCartSwith = !this.upCartSwith;
      this.getCart() 
    },
    //Ссылкана товар
    getLink(id) {
      return {name: "PageProduct", params: {shop_id: this.shop_id, id: id, category_id: this.category_id}}
      //category_id
      // return "/shop/" + this.shop_id + "/product/" + id
    },
    //Ссылкана на заявку
    getLinkState(id) {
      return "/shop/" + this.shop_id + "/page_state/" + id
    },
  },
  watch: {

    sortItem() {
      this.products = [];
      this.page =1
      this.loadProducts()
    }

  },
  mounted() {

    if (this.shop_id) {
      this.addListeners();
      this.loadProducts();
      this.checkEndList();
     
    }
    window.addEventListener('resize', this.reCalcFSizeOnResize.bind(this))

  },
  setup() {
    return {textFit};
  },
};
</script>

<style lang="scss" scoped>
.description_text {
  margin: 0;
  //text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.products {
  display: grid;
  //max-height: 600px;
  //overflow-y: auto;
  grid-gap: 12px 16px;
  grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
  //grid-template-columns: repeat(2, 1fr);

  @media (max-width: 400px) {
    grid-gap: 12px;
  }
}

.product {

  text-align: center;

  padding: 8px;
  padding-bottom: 15px;

  @media (max-width: 400px) {
    padding-bottom: 12px;
  }
}

.products__container {
  padding: 0;
}

.product__image {
  margin-bottom: 8px;
}

.product__title {
  color: var(--default_background);
  font-size: 14px;
  text-decoration: underline;
}


.product__name {
  min-height: 32px;
  max-height: 32px;
  overflow: hidden;
  text-overflow: clip;
}

.product__description {
  color: var(--global_fourth_color);
  margin: 7px 0;
  font-size: 10px;
}

.product__description div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 60px;
  max-height: 80px;
}

.product__price {
  font-size: 12px;
  margin-bottom: 5px;
}

.product__interaction {
  display: flex;
  justify-content: center;

  margin: 0 auto;

  width: 80%;
}

.product__show-more {
  font-size: 11px;

  display: flex;
  align-items: center;
  column-gap: 5px;

  margin: 0 auto;
  margin-top: 20px;
  padding: 9px;

  max-width: 115px;
}
</style>
