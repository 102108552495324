<template>
  <div class="selector-container">
    <div class="selector-title">
      <slot name="title"/>
    </div>
    <div class="selector-button item-option"
         @click.stop="openMenu"
    >
      {{ currentSelect }}
      <span>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.01807 6.74878L6.0825 6.81322L11.3584 1.53734L10.1156 0.294592L5.63125 4.77897L1.21719 0.364906L0.0447706 1.53732L5.63715 7.1297L6.01807 6.74878Z"
                fill="black"/>
        </svg>
      </span>
    </div>
    <div class="options-absolute-container">
      <div class="options-container"
           v-if="open">
        <div class="options item-option"
             v-for="(opt, i) in options"
             :class="selected[i] ? 'active' : ''"
             :key="i"
             @click.stop="selectOption(opt, i)"
        > {{ opt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelectorShow",
  data() {
    return {
      currentSelect: '',
      open: false,
      selected: [],
    }
  },
  props: {
    options: {
      type: Array,
    },
    modelValue: {},
    state: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    selectOption(opt, i) {
      this.currentSelect = opt;
      this.selected.forEach((el, i, arr) => arr[i] = false);
      this.selected[i] = true;
      this.$emit('update:modelValue', this.currentSelect);
      this.open = false;
    },
    openMenu() {
      this.open = !this.open
    }
  },

  mounted() {
    this.options.forEach((el, i) => {
      if (el === this.modelValue) {
        this.currentSelect = el
        this.selected[i] = true;
      } else this.selected[i] = false
    })
  },

  watch: {
    state() {
      this.open = false
    }
  }
}
</script>

<style scoped>

.selector-container {
  margin: 0 20px 20px 0;
  min-width: 290px;
  max-width: 300px;
  position: relative;
}

.container-selected-options {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
}

.options-container {
  background: #EEF4F8;
  border: 1px solid #BECBD4;
  position: relative;
  top: -14px;
  padding-top: 10px;
  border-radius: 6px;
}

.options-absolute-container {
  position: absolute;
  bottom: -173px;
  width: 100%;
  max-height: 175px;
  overflow-y: auto;
}

.selector-button {
  border: 1px solid #BECBD4;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  background: #EEF4F8;
  position: relative;
  border-radius: 6px;
}

.options {
  background: #EEF4F8;
  border-top: 1px solid #BECBD4;
  border-radius: 0 0 6px 6px;
}

.options:hover {
  background: #e1eaef;
}

.item-option {
  padding: 13px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.selected-options {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
  margin: 7px 10px 7px 0;
}

.button-delete-option {
  margin-left: 5px;
  position: relative;
  top: 2px;
}


.active {
  color: #20A0FF;
  position: relative;
}

.active:after {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 13px;
  top: 12px;
  content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.12671 9.79917C5.01721 9.92645 4.82781 9.93616 4.70584 9.82073L0.0950729 5.45679C-0.0268975 5.34137 -0.0322006 5.14715 0.0831582 5.02518L1.55217 3.4729C1.6676 3.35093 1.86182 3.34562 1.98379 3.46098L4.50247 5.84467C4.62444 5.9601 4.81383 5.95039 4.92334 5.82312L9.84396 0.105798C9.95346 -0.0214758 10.1473 -0.0360075 10.2745 0.0735661L11.8942 1.46765C12.0214 1.57716 12.036 1.77096 11.9264 1.89823L5.12671 9.79917Z' fill='%2320A0FF'/%3E%3C/svg%3E%0A");
}

.selector-title {
  color: #7E7E7E;
  font-size: 12px;
  margin: 0 0 6px;
}

</style>