<template>
    <div
    class="image"
    v-lazy-container="{ selector: 'img' }">
        <img
            draggable="false"
        v-if="name" 
        :data-src="'https://mag.ivitrina.store/media/'+name"  
        :alt="alt"> 
        <svg v-if="stocks && stocks.length>0"
            v-show="stocks[0] && stocks[0].data.nameplate"
            class="image__svg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <image :xlink:href="'https://mag.ivitrina.store/media/'+stocks[0].data.nameplate.link" :src="''" />
        </svg>
    </div>
</template>

<script>

export default {
    name: 'AppImage',
    data() {
        return {
        }
    },
    props: {
        stocks: {
            type: Array,
            defaullt(){ return [] }
        },
     
        name: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: ''
        },
    },
    watch:{
        name(){
            console.log(this.name)
        },
        stock(){
            console.log(this.stock)
        }
    }
}
</script>

<style lang="scss" scoped>
    .image {
        padding-top: 100%;

        position: relative;

        img {
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;

            //width: 100%;
            height: 100%;
        }

        &:hover {
            .image__delete {
                display: block;
            }
        }
    }

    .image__svg {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    .image__delete {
        display: none;

        position: absolute;
        top: 3px;
        right: 3px;
    }
</style>